import History from "./history";
import Store from "../store/store";
import { logoutUser } from "../store/user/actions";
import * as Storage from "./localStorage";

export const preventUnauthorizedRequest = (response) => {
  if (response.status >= 400 && response.status <= 500) {
    if (response.status === 401) {
      Storage.removeUserAuth();
      Store.dispatch(logoutUser());
      History.push("/login");
    }
    if (response.status === 422) {
      return response;
    }
    return;
  }
  else {
    return response;
  }
}
