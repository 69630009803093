const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null,
  selectedEntity: null,
  selectedEntityPending: false,
  selectedEntityError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "CLEAR_AGENTS":
      return {
        ...initialState
      };

    case "GET_AGENTS":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: true,
        loadEntitiesError: null
      };

    case "GET_AGENTS_COMPLETE":
      return {
        ...state,
        entities: action.data,
        loadEntitiesPending: false,
        loadEntitiesError: null,
      };

    case "GET_AGENTS_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message,
      };

    case "GET_SELECTED_AGENT":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: true,
        selectedEntityError: null
      };

    case "GET_SELECTED_AGENT_COMPLETE":
      return {
        ...state,
        selectedEntity: action.data,
        selectedEntityPending: false,
        selectedEntityError: null
      };

    case "GET_SELECTED_AGENT_FAILURE":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: false,
        selectedEntityError: action.data
      };

    default:
      return state;
  }
}

export const getAgents = (state) => (state.entities || []).sort((a, b) => a.unknownFlag > b.unknownFlag ? 1 : -1);

export const getSelectedAgent = (state) => state.selectedEntity;
