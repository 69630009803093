import React, { Component } from 'react';
import Store from "./store/store";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import * as WorkActionDefinitionActions from './store/workActionDefinitions/actions';
import History from "./utils/history";
import * as UserActions from "./store/user/actions";
import OrchardDetails from "./containers/orchards/orchardDetails";
import WorkActionDetails from "./containers/orchards/workActionDetails";
import OrchardNoteDetails from "./containers/orchards/orchardNoteDetails";
import HarvestDetails from "./containers/orchards/harvestDetails";
import Navigation from "./containers/layout/navigation";
import InitializeApp from './InitializeApp';
import asyncComponent from "./containers/async/asyncComponent";

export class App extends Component {

  componentDidMount() { // eslint-disable-line
    //Store.dispatch(WorkActionDefinitionActions.getWorkActionDefinitions());
  }

  setActions = (handlers) => {
    const { updateHandler, deleteHandler } = handlers || {};
    this.setState({
      updateHandler,
      deleteHandler
    });
  };

  getOrchardDetailsWithActions = props => <OrchardDetails setActions={this.setActions} {...props} />;
  getWorkActionDetailssWithActions = props => <WorkActionDetails setActions={this.setActions} {...props} />;
  getOrchardNoteDetailsWithActions = props => <OrchardNoteDetails setActions={this.setActions} {...props} />;
  getHarvestDetailsWithActions = props => <HarvestDetails setActions={this.setActions} {...props} />;

  AsyncCookies = asyncComponent(() => import("./containers/legal"));
  AsyncLogin = asyncComponent(() => import("./containers/login/login"));
  AsyncSignup = asyncComponent(() => import("./containers/login/signup"));
  AsyncChangePasswordRequest = asyncComponent(() => import("./containers/login/changePasswordRequest"));
  AsyncChangePasswordForm = asyncComponent(() => import("./containers/login/changePasswordForm"));
  AsyncTerms = asyncComponent(() => import("./containers/legal/terms"));
  AsyncAgentDetails = asyncComponent(() => import("./containers/agents/agentDetails"));

  AsyncGuide = asyncComponent(() => import("./containers/guide/guide"));
  AsyncAgents = asyncComponent(() => import("./containers/agents/agents"));
  AsyncPests = asyncComponent(() => import("./containers/pests/pests"));
  AsyncAdvices = asyncComponent(() => import("./containers/advices/advices"));
  AsyncAdvice = asyncComponent(() => import("./containers/advices/advice"));
  AsyncSupport = asyncComponent(() => import("./containers/support/support"));
  AsyncMills = asyncComponent(() => import("./containers/processing/mills"));

  AsyncOrchardsList = asyncComponent(() => import("./containers/orchards/orchardsList"));
  AsyncCreateNewOrchard = asyncComponent(() => import("./containers/orchards/createNewOrchard"));
  AsyncUpdateOrchardForm = asyncComponent(() => import("./containers/orchards/updateOrchardForm"));
  AsyncUpdateOrchardWorkAction = asyncComponent(() => import("./containers/orchards/updateOrchardWorkAction"));
  AsyncCreateNewWorkAction = asyncComponent(() => import("./containers/orchards/createNewWorkAction"));
  AsyncCreateNewNote = asyncComponent(() => import("./containers/orchards/createNewNote"));
  AsyncCreateNewHarvest = asyncComponent(() => import("./containers/orchards/createNewHarvest"));
  AsyncUpdateHarvest = asyncComponent(() => import("./containers/orchards/updateHarvest"));

  AsyncEmailVerification = asyncComponent(() => import("./containers/login/emailVerification"));

  AsyncHomepage = asyncComponent(() => import("./containers/homepage/homepage"));

  state = {
    deleteHandler: null,
    updateHandler: null,
  };

  render() {
    const { updateHandler, deleteHandler } = this.state;

    return (
      <InitializeApp>
        <Router history={History}>
          <div className="page-wrapper">
            <Navigation onDelete={deleteHandler} onUpdate={updateHandler} />
            <div className="page-content-wrapper">
              <Switch>
                <Route exact path="/login" component={this.AsyncLogin} />
                <Route exact path="/register" component={this.AsyncSignup} />
                <Route exact path="/resetpassword/:email/:token" component={this.AsyncChangePasswordForm} />
                <Route exact path="/resetpassword" component={this.AsyncChangePasswordRequest} />
                <Route exact path="/cookies" component={this.AsyncCookies} />
                <Route exact path="/terms" component={this.AsyncTerms} />
                <PrivateRoute exact path="/verify-email" component={this.AsyncEmailVerification} />
                <PrivateRoute exact path="/orchards" component={this.AsyncOrchardsList} />
                <PrivateRoute exact path="/orchards/createnew" component={this.AsyncCreateNewOrchard} />
                <PrivateRoute exact path="/orchards/:id/update" component={this.AsyncUpdateOrchardForm} />
                <PrivateRoute exact path="/orchards/:id" component={this.getOrchardDetailsWithActions} />
                <PrivateRoute exact path="/orchards/:orchardId/workactions/:workActionId/update/:workActionType" component={this.AsyncUpdateOrchardWorkAction} />
                <PrivateRoute exact path="/orchards/:orchardId/workactions/:workActionId" component={this.getWorkActionDetailssWithActions} />
                <PrivateRoute exact path="/orchards/:orchardId/notes/:noteId" component={this.getOrchardNoteDetailsWithActions} />
                <PrivateRoute exact path="/orchards/:orchardId/harvests/:harvestId" component={this.getHarvestDetailsWithActions} />
                <PrivateRoute exact path="/orchards/:orchardId/harvests/:harvestId/update" component={this.AsyncUpdateHarvest} />
                <PrivateRoute exact path="/orchards/:orchardId/createworkaction" component={this.AsyncCreateNewWorkAction} />
                <PrivateRoute exact path="/orchards/:orchardId/createnote" component={this.AsyncCreateNewNote} />
                <PrivateRoute exact path="/orchards/:orchardId/createharvest" component={this.AsyncCreateNewHarvest} />
                <Route exact path="/pests/:pestId/agents" component={this.AsyncAgents} />
                <Route exact path="/pests" component={this.AsyncPests} />
                <Route exact path="/agents/:agentId" component={this.AsyncAgentDetails} />
                <Route exact path="/guide" component={this.AsyncGuide} />
                <Route exact path="/processing" component={this.AsyncMills} />
                <Route path="/advices/:adviceId" component={this.AsyncAdvice} />
                <Route path="/advices" component={this.AsyncAdvices} />
                <Route path="/support" component={this.AsyncSupport} />
                <Route path="/" component={this.AsyncHomepage} />
              </Switch>
            </div>
          </div>
        </Router>
      </InitializeApp>
    )
  };
}

export default App;
