import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isUserLoggedIn } from "./store/user/reducer";

const logAndRedirect = (location) => {
  console.log("not authenticated. redirecting to login...");

  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location }
      }}
    />
  );
};

const PrivateRoute = ({ isUserLoggedIn, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isUserLoggedIn ? (
        <Component {...props} />
      ) : logAndRedirect(props.location)
    }
  />
);

const mapStateToProps = (state) => ({
  isUserLoggedIn: isUserLoggedIn(state.user),
});

export default connect(mapStateToProps)(PrivateRoute);
