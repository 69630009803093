import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import * as DateHelpers from "../../helpers/date";
import { NavLink, withRouter } from "react-router-dom";
import * as OrchardNotesActions from '../../store/orchardNotes/actions';
import * as OrchardNotesSelectors from '../../store/orchardNotes/reducer';
import ReactGA from "react-ga";
import "./orchardNotes.scss";

const OrchardNotes = ({
  consent,
  selectedOrchard,
  getNotes,
  isLoadNotesPending,
  notes,
  history,
}) => {

  useLayoutEffect(() => {
    getNotes();

    if ((consent || {}).analytics) {
      ReactGA.event({
        category: 'Maslinik',
        action: 'Lista zapažanja'
      });
    }
  }, []);

  const truncateDescription = (text) => (text || "").length > 30 ? `${text.slice(0, 30)}...` : text;

  const openOrchardNote = (orchardId, note) => {
    history.push(`/orchards/${orchardId}/notes/${note.id}`);
  };

  return (
    <div className="notes-list">
      <NavLink to={`/orchards/${selectedOrchard.id}/createnote`} className="orchard-details__add-new-btn">
        <i className="fas fa-plus"></i>
        {(notes || []).length < 1 && (
          <div className="orchard-details__add-new-btn-tooltip"><div>Za unos novog zapažanja kliknite <i className="fas fa-arrow-right"></i></div></div>
        )}
      </NavLink>
      {isLoadNotesPending && <div className="loader"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
      {!isLoadNotesPending && (notes || []).length < 1 && (
        <div className="todo">
          Vrijedi zabilježiti
        <div className="todo-description">Počela cvatnja, pojavio se nametnik, kišno ljeto, niske temperature,...</div>
        <div className="todo-description">Također, kad budete tražili pomoć savjetnika, svako zapažanje/opaska/komentar će biti korisno.</div>
        </div>
      )
      }
      {!isLoadNotesPending && (notes || []).map((note, index) => (
        <div
          className="notes-list__item"
          key={index}
          onClick={() => openOrchardNote(selectedOrchard.id, note)}
        >
          <div className={classnames("notes-list__item-icon", { withpest: note.pestId })}>
            <i className="fas fa-comment"></i>
          </div>
          <div className="notes-list__item-info-wrapper">
            <div className="notes-list__item-description">
              {note.note}
            </div>
          </div>
          <div className="notes-list__item-date">
            <span>{DateHelpers.stringToDate(note.date).getFullYear()}</span><br />
            {`${DateHelpers.stringToDate(note.date).getDate()}. ${DateHelpers.numberToMonth(DateHelpers.stringToDate(note.date).getMonth() + 1).shortName}`}
          </div>
        </div>
      ))
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  consent: state.consent,
  notes: OrchardNotesSelectors.getOrchardNotes(state),
  isLoadNotesPending: OrchardNotesSelectors.getLoadEntitiesPending(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getNotes: () => dispatch(OrchardNotesActions.getOrchardNotes(ownProps.match.params.id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrchardNotes));
