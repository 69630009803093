import React, { useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import * as DateHelpers from "../../helpers/date";
import * as UIActions from "../../store/ui/actions";
import * as HarvestsSelectors from "../../store/harvests/reducer";
import * as HarvestActions from "../../store/harvests/actions";
import ReactGA from "react-ga";
import "./harvestDetails.scss";

const HarvestDetails = ({
  consent,
  selectedHarvest,
  isHarvestLoading,
  getHarvest,
  setBackButtonRoute,
  setActions,
  setPageTitle,
  match: { params: { orchardId } },
  dispatch,
  history,
}) => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    getHarvest();
    setBackButtonRoute(`/orchards/${orchardId}?tab=2`);
    setPageTitle("Berba");

    if ((consent || {}).analytics) {
      const page = "/orchards/Id/harvests/nId";
      ReactGA.set({ page, title: "Detalji berbe" });
      ReactGA.pageview(page);
    }

    return () => {
      setBackButtonRoute("");
      if (setActions)
        setActions(null);
    };
  }, []);

  useEffect(() => {
    if (setActions && orchardId) {
      setActions({
        deleteHandler,
        updateHandler
      });
    }
  }, [orchardId, selectedHarvest]);

  function redirect() {
    history.replace(`/orchards/${orchardId}?tab=2`);
  };

  function updateHandler() {
    history.push(`/orchards/${orchardId}/harvests/${selectedHarvest.id}/update`);
  }

  function deleteHandler() {
    if (!selectedHarvest) {
      return false;
    }
    dispatch(HarvestActions.deleteHarvest(selectedHarvest, orchardId, redirect));
  };

  return (
    <div className="harvest-details__wrapper">
      <div className="harvest-details__details">
        <label>Datum berbe</label>
        <p>
          {selectedHarvest &&
            <span>{`${DateHelpers.stringToDate(selectedHarvest.harvestDate).getDate()}. ${DateHelpers.numberToMonth(DateHelpers.stringToDate(selectedHarvest.harvestDate).getMonth() + 1).shortName}, ${DateHelpers.stringToDate(selectedHarvest.harvestDate).getFullYear()}`}</span>
          }
        </p>
        <label>Radnih sati:</label>
        {(selectedHarvest || {}).duration}
        <label>Ubrano maslina</label>
        {(selectedHarvest || {}).olivesWeight} kg
        <label>Prerađeno u uljari</label>
        {(selectedHarvest || {}).mill ? (selectedHarvest.mill) : "-"}
        <label>Datum prerade</label>
        <p>
          {(selectedHarvest || {}).isProcessed &&
            <span>{`${DateHelpers.stringToDate(selectedHarvest.processingDate).getDate()}. ${DateHelpers.numberToMonth(DateHelpers.stringToDate(selectedHarvest.processingDate).getMonth() + 1).shortName}, ${DateHelpers.stringToDate(selectedHarvest.processingDate).getFullYear()}`}</span>
          }
          {!(selectedHarvest || {}).isProcessed &&
            <span>-</span>
          }
        </p>
        <label>Dobiveno ulja</label>
        {(selectedHarvest || {}).oilLiters ? `${selectedHarvest.oilLiters} l` : "-"}
        <label>Napomene</label>
        {selectedHarvest && selectedHarvest.notes.split('\n').map((item, key) => {
          return (
            <p key={key}>
              {item}
              <br />
            </p>
          )
        }
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  consent: state.consent,
  selectedHarvest: HarvestsSelectors.getSelectedHarvest(state.harvests),
  isHarvestLoading: HarvestsSelectors.getSelectedHarvestPending(state.harvests),
  selectedHarvestErrors: HarvestsSelectors.getSelectedHarvestErrors(state.harvests),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { orchardId, harvestId } } } = ownProps;
  return {
    dispatch,
    getHarvest: () => dispatch(HarvestActions.getSelectedHarvest(orchardId, harvestId)),
    setBackButtonRoute: (route) => dispatch(UIActions.setBackButtonRoute(route)),
    setPageTitle: (title) => dispatch(UIActions.setPageTitle(title)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HarvestDetails);