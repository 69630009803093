const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null,
  selectedEntity: null,
  selectedEntityPending: false,
  selectedEntityError: null,
  createNewPending: false,
  createNewFailure: null,
  updatePending: false,
  updateError: null,
  deleteEntityPending: false,
  deleteEntityError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "GET_ORCHARDS":
      return {
        ...state,
        loadEntitiesPending: true,
        entities: null
      };

    case "GET_ORCHARDS_COMPLETE":
      return {
        ...state,
        entities: action.data,
        loadEntitiesPending: false,
        loadEntitiesError: null
      };

    case "GET_ORCHARDS_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message
      };

    case "GET_SELECTED_ORCHARD":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: true,
        selectedEntityError: null
      };

    case "GET_SELECTED_ORCHARD_COMPLETE":
      return {
        ...state,
        selectedEntity: action.data,
        selectedEntityPending: false,
        selectedEntityError: null
      };

    case "GET_SELECTED_ORCHARD_FAILURE":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: false,
        selectedEntityError: action.data
      };

    case "CREATE_NEW_ORCHARD":
      return {
        ...state,
        createNewPending: true,
        createNewFailure: null
      };

    case "CREATE_NEW_ORCHARD_COMPLETE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: null
      };


    case "CREATE_NEW_ORCHARD_FAILURE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: action.data.message
      };

    case "UPDATE_ORCHARD":
      return {
        ...state,
        updatePending: true,
        updateError: null
      };

    case "UPDATE_ORCHARD_COMPLETE":
      const elIndex = (state.entities || []).findIndex(item => item.id === action.data.id);
      
      return {
        ...state,
        selectedEntity: action.data,
        entities: elIndex !== -1 && state.entities ? [...state.entities.slice(0, elIndex), action.data, ...(state.entities.slice(elIndex + 1))] : state.entities,
        updatePending: false,
        updateError: null
      };

    case "UPDATE_ORCHARD_FAILURE":
      return {
        ...state,
        updatePending: false,
        updateError: action.data.message
      };

    case "CREATE_NEW_WORKACTION_COMPLETE":
      const { workAction, orchardId } = action.data;
      console.log("reducer", workAction, orchardId);

      let newOrchardWorkActions = (state.entities.find(o => o.id === orchardId) || {}).workActions || [];
      newOrchardWorkActions = [...newOrchardWorkActions, workAction];

      const orchardIndex = state.entities.findIndex(or => or.id === orchardId);
      const newOrchardVersion = {
        ...state.entities[orchardIndex], workActions: newOrchardWorkActions
      };

      const newState1 = {
        ...state, entities: Object.assign([...state.entities], { [orchardIndex]: newOrchardVersion })
      };

      return newState1;

    case "DELETE_ORCHARD":
      return {
        ...state,
        deleteEntityPending: true,
        deleteEntityError: null
      };

    case "DELETE_ORCHARD_COMPLETE":
      return {
        ...state,
        deleteEntityPending: false,
        deleteEntityError: null
      };

    case "DELETE_ORCHARD_FAILURE":
      return {
        ...state,
        deleteEntityPending: false,
        deleteEntityError: action.data
      };

    default:
      return state;
  }
}

export const getSelectedEntityPending = (state) => state.orchards.selectedEntityPending;
export const getDeleteEntityPending = (state) => state.orchards.deleteEntityPending;
