const initialState = {
  consentGiven: false,
  technical: true,
  functional: false,
  analytics: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "SET_ACCEPT_ALL_COOKIES":
      return {
        ...state,
        consentGiven: true,
        technical: true,
        functional: true,
        analytics: true,
      };
      break;

    case "SET_ACCEPT_NECESSARY_COOKIES":
      return {
        ...state,
        consentGiven: true,
        technical: true,
        functional: false,
        analytics: false,
      };
      break;

    case "SET_ACCEPT_OPTIONAL_COOKIES":
      return {
        ...state,
        consentGiven: true,
        ...action.data
      };
      break;

    default:
      return state;
  }
}
