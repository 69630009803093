import * as Storage from "../../utils/localStorage";

export const Types = {
  LOGIN: "LOGIN",
  LOGIN_COMPLETE: "LOGIN_COMPLETE",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  SIGNUP: "SIGNUP",
  SIGNUP_COMPLETE: "SIGNUP_COMPLETE",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  GENERATE_EMAIL_VERIFY_CODE: "GENERATE_EMAIL_VERIFY_CODE",
  GENERATE_EMAIL_VERIFY_CODE_COMPLETE: "GENERATE_EMAIL_VERIFY_CODE_COMPLETE",
  GENERATE_EMAIL_VERIFY_CODE_FAILURE: "GENERATE_EMAIL_VERIFY_CODE_FAILURE",
  CONFIRM_EMAIL_ADDRESS: "CONFIRM_EMAIL_ADDRESS",
  CONFIRM_EMAIL_ADDRESS_COMPLETE: "CONFIRM_EMAIL_ADDRESS_COMPLETE",
  CONFIRM_EMAIL_ADDRESS_FAILURE: "CONFIRM_EMAIL_ADDRESS_FAILURE",
  REQUEST_PASSWORD_RESET: "REQUEST_PASSWORD_RESET",
  REQUEST_PASSWORD_RESET_COMPLETE: "REQUEST_PASSWORD_RESET_COMPLETE",
  REQUEST_PASSWORD_RESET_FAILURE: "REQUEST_PASSWORD_RESET_FAILURE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_COMPLETE: "CHANGE_PASSWORD_COMPLETE",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
};

export const logoutUser = dispatch => {
  Storage.removeUserAuth();
  return { type: Types.LOGOUT };
}

export const setLoginPending = dispatch => {
  return { type: Types.LOGIN };
}

export const loginComplete = (data) => ({
  type: Types.LOGIN_COMPLETE,
  data
});

export const loginFailure = (data) => ({
  type: Types.LOGIN_FAILURE,
  data
});

export const doUserLogin = (email, password, redirectOnSuccess) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setLoginPending());

  const headers = new Headers();
  headers.append("Content-type", "application/json");

  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/jwt/login`,
      {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers
      });

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(loginComplete(responseJson));
      Storage.setUserAuth(responseJson);
      redirectOnSuccess();
    }
    else {
      dispatch(loginFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

export const setSignupPending = dispatch => {
  return { type: Types.SIGNUP };
}

export const signupComplete = (data) => ({
  type: Types.SIGNUP_COMPLETE,
  data
});

export const signupFailure = (data) => ({
  type: Types.SIGNUP_FAILURE,
  data
});

export const doUserSignup = (email, password, redirectOnSuccess) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setSignupPending());

  const headers = new Headers();
  headers.append("Content-type", "application/json");

  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/jwt/register`,
      {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers
      });

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(signupComplete(responseJson));
      Storage.setUserAuth(responseJson);
      redirectOnSuccess();
    }
    else {
      dispatch(signupFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

// confirm email address
export const setConfirmEmailAddressPending = dispatch => {
  return { type: Types.CONFIRM_EMAIL_ADDRESS };
};

export const confirmEmailAddressComplete = () => ({
  type: Types.CONFIRM_EMAIL_ADDRESS_COMPLETE,
});

export const confirmEmailAddressFailure = (data) => ({
  type: Types.CONFIRM_EMAIL_ADDRESS_FAILURE,
  data
});

export const confirmEmailAddress = (code) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setConfirmEmailAddressPending());

  const headers = new Headers();
  headers.append("Content-type", "application/json");

  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/users/confirmemail`,
      {
        method: "POST",
        body: code,
        headers
      });

    if (response.ok) {
      let userProfile = Storage.getUserAuth();
      if (userProfile) {
        userProfile.user.isEmailConfirmed = true;
        Storage.setUserAuth(userProfile);
      }
      dispatch(confirmEmailAddressComplete());
    }
    else {
      const responseJson = await response.json();
      dispatch(confirmEmailAddressFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

// generate email confirmation code
export const setGenerateEmailVerifyCodePending = dispatch => {
  return { type: Types.GENERATE_EMAIL_VERIFY_CODE };
};

export const generateEmailVerifyCodeComplete = () => ({
  type: Types.GENERATE_EMAIL_VERIFY_CODE_COMPLETE,
});

export const generateEmailVerifyCodeFailure = (data) => ({
  type: Types.GENERATE_EMAIL_VERIFY_CODE_FAILURE,
  data
});

export const generateEmailVerifyCode = () => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGenerateEmailVerifyCodePending());

  const headers = new Headers();
  headers.append("Content-type", "application/json");

  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/users/emailconfirmationcode`,
      {
        method: "POST",
        headers
      });

    if (response.ok) {
      dispatch(generateEmailVerifyCodeComplete());
    }
    else {
      const responseJson = await response.json();
      dispatch(generateEmailVerifyCodeFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

// request password reset
export const setRequestPasswordResetPending = dispatch => {
  return { type: Types.REQUEST_PASSWORD_RESET };
};

export const requestPasswordResetComplete = () => ({
  type: Types.REQUEST_PASSWORD_RESET_COMPLETE,
});

export const requestPasswordResetFailure = (data) => ({
  type: Types.REQUEST_PASSWORD_RESET_FAILURE,
  data
});

export const requestPasswordReset = (email) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setRequestPasswordResetPending());

  const headers = new Headers();
  headers.append("Content-type", "application/json");

  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/users/createrecovertoken/${email}`,
      {
        method: "POST",
        headers
      });

    if (response.ok) {
      dispatch(requestPasswordResetComplete());
    }
    else {
      const responseJson = await response.json();
      dispatch(requestPasswordResetFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

// request password reset
export const setChangePasswordPending = dispatch => {
  return { type: Types.CHANGE_PASSWORD };
};

export const changePasswordComplete = () => ({
  type: Types.CHANGE_PASSWORD_COMPLETE,
});

export const changePasswordFailure = (data) => ({
  type: Types.CHANGE_PASSWORD_FAILURE,
  data
});

export const changePassword = (email, password, token) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setChangePasswordPending());

  const headers = new Headers();
  headers.append("Content-type", "application/json");

  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/users/changepassword`,
      {
        method: "POST",
        body: JSON.stringify({ email, password, token }),
        headers
      });

    if (response.ok) {
      dispatch(changePasswordComplete());
    }
    else {
      const responseJson = await response.json();
      dispatch(changePasswordFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
