import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import * as HarvestsSelectors from "../../store/harvests/reducer";
import * as HarvestsActions from "../../store/harvests/actions";
import * as DateHelpers from "../../helpers/date";
import ReactGA from "react-ga";
import "./harvestsList.scss";

const HarvestsList = ({
  consent,
  selectedOrchard,
  harvestsList,
  isHarvestListLoadPending,
  harvestsListLoadErros,
  getHarvestsList,
  history,
}) => {

  useLayoutEffect(() => {
    getHarvestsList();

    if ((consent || {}).analytics) {
      ReactGA.event({
        category: 'Maslinik',
        action: 'Lista berbi'
      });
    }
  }, []);

  const openHarvest = (orchardId, harvest) => {
    history.push(`/orchards/${orchardId}/harvests/${harvest.id}`);
  };

  return (
    <div className="harvests-list">
      <NavLink to={`/orchards/${selectedOrchard.id}/createharvest`} className="orchard-details__add-new-btn">
        <i className="fas fa-plus"></i>
        {(harvestsList || []).length < 1 && (
          <div className="orchard-details__add-new-btn-tooltip"><div>Za unos novog zapisa kliknite <i className="fas fa-arrow-right"></i></div></div>
        )}
      </NavLink>
      {isHarvestListLoadPending && (
        <div className="loader"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
      )
      }
      {!isHarvestListLoadPending && (harvestsList || []).length < 1 && (
        <div className="todo">
          Konac djelo krasi
          <div className="todo-description">Unesite detalje berbe i prerade maslina...</div>
        </div>
      )
      }
      {!isHarvestListLoadPending && (harvestsList || []).map((harvest, index) => (
        <div
          className="harvests-list__item"
          key={index}
          onClick={() => openHarvest(selectedOrchard.id, harvest)}
        >
          <div className="harvests-list__item-icon">
            <i className="fas fa-people-carry"></i>
          </div>
          <div className="harvests-list__item-info-wrapper">
            <div className="harvests-list__item-name">
              {harvest.olivesWeight} kg
            </div>
            <div className="harvests-list__item-description">
              {harvest.notes}
            </div>
            <div className="harvests-list__item-duration">
              radnih sati: {(harvest.duration || 0) === 0 ? "-" : harvest.duration}
            </div>
          </div>
          <div className="harvests-list__item-date">
            <span>{DateHelpers.stringToDate(harvest.harvestDate).getFullYear()}</span><br />
            {`${DateHelpers.stringToDate(harvest.harvestDate).getDate()}. ${DateHelpers.numberToMonth(DateHelpers.stringToDate(harvest.harvestDate).getMonth() + 1).shortName}`}
          </div>
        </div>
      ))
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  consent: state.consent,
  harvestsList: HarvestsSelectors.getHarvestsList(state.harvests),
  isHarvestListLoadPending: HarvestsSelectors.getLoadEntitiesPending(state.harvests),
  harvestsListLoadErros: HarvestsSelectors.getLoadEntitiesErrors(state.harvests),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getHarvestsList: () => dispatch(HarvestsActions.getHarvests(ownProps.match.params.id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HarvestsList));
