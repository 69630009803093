import React, { useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import * as DateHelpers from "../../helpers/date";
import * as UIActions from "../../store/ui/actions";
import * as OrchardNotesSelectors from "../../store/orchardNotes/reducer";
import { getAllPests } from "../../store/pests/reducer";
import * as OrchardNoteActions from "../../store/orchardNotes/actions";
import ReactGA from "react-ga";
import "./orchardNoteDetails.scss";

const OrchardNoteDetails = ({
  consent,
  selectedNote,
  isNoteLoading,
  getOrchardNote,
  pests,
  setBackButtonRoute,
  setActions,
  setPageTitle,
  match: { params: { orchardId } },
  dispatch,
  history,
}) => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    getOrchardNote();
    setBackButtonRoute(`/orchards/${orchardId}?tab=1`);
    setPageTitle("Zapažanje");

    if ((consent || {}).analytics) {
      const page = "/orchards/Id/notes/nId";
      ReactGA.set({ page, title: "Detalji zapažanja" });
      ReactGA.pageview(page);
    }

    return () => {
      setBackButtonRoute("");
      if (setActions)
        setActions(null);
    };
  }, []);

  useEffect(() => {
    if (setActions && orchardId) {
      setActions({
        deleteHandler: deleteHandler,
      });
    }
  }, [orchardId, selectedNote]);

  function redirect() {
    history.replace(`/orchards/${orchardId}?tab=1`);
  };

  function deleteHandler() {
    if (!selectedNote) {
      return false;
    }
    dispatch(OrchardNoteActions.deleteOrchardNote(selectedNote, orchardId, redirect));
  };

  function getPestName(pestId) {
    return ((pests || []).find(p => p.id === pestId) || {}).name;
  }

  return (
    <div className="orchard-note-details__wrapper">
      <div className="orchard-note-details__details">
        <label>Datum</label>
        <p>
          {selectedNote &&
            <span>{`${DateHelpers.stringToDate(selectedNote.date).getDate()}. ${DateHelpers.numberToMonth(DateHelpers.stringToDate(selectedNote.date).getMonth() + 1).shortName}, ${DateHelpers.stringToDate(selectedNote.date).getFullYear()}`}</span>

          }
        </p>
        <label>Komentar</label>
        {selectedNote && selectedNote.note.split('\n').map((item, key) => {
          return (
            <p key={key}>
              {item}
              <br />
            </p>
          )
        }
        )}
        <label>Uočen nametnik:</label>
        {selectedNote && selectedNote.pestId && <div className="orchard-note-details__pest-name">{getPestName(selectedNote.pestId)}</div>}
        {(!selectedNote || !selectedNote.pestId) && <div className="orchard-note-details__pest-name">-</div>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  consent: state.consent,
  selectedNote: OrchardNotesSelectors.getSelectedOrchardNote(state),
  isNoteLoading: OrchardNotesSelectors.getLoadSelectedEntityPending(state),
  pests: getAllPests(state.pests),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { orchardId, noteId } } } = ownProps;
  return {
    dispatch,
    getOrchardNote: () => dispatch(OrchardNoteActions.getSelectedOrchardNote(orchardId, noteId)),
    setBackButtonRoute: (route) => dispatch(UIActions.setBackButtonRoute(route)),
    setPageTitle: (title) => dispatch(UIActions.setPageTitle(title)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrchardNoteDetails);