import { preventUnauthorizedRequest } from "../../utils/interceptors";

export const Types = {
  GET_HARVESTS: "GET_HARVESTS",
  GET_HARVESTS_COMPLETE: "GET_HARVESTS_COMPLETE",
  GET_HARVESTS_FAILURE: "GET_HARVESTS_FAILURE",
  GET_SELECTED_HARVEST: "GET_SELECTED_HARVEST",
  GET_SELECTED_HARVEST_COMPLETE: "GET_SELECTED_HARVEST_COMPLETE",
  GET_SELECTED_HARVEST_FAILURE: "GET_SELECTED_HARVEST_FAILURE",
  CREATE_NEW_HARVEST: "CREATE_NEW_HARVEST",
  CREATE_NEW_HARVEST_COMPLETE: "CREATE_NEW_HARVEST_COMPLETE",
  CREATE_NEW_HARVEST_FAILURE: "CREATE_NEW_HARVEST_FAILURE",
  UPDATE_HARVEST: "UPDATE_HARVEST",
  UPDATE_HARVEST_COMPLETE: "UPDATE_HARVEST_COMPLETE",
  UPDATE_HARVEST_FAILURE: "UPDATE_HARVEST_FAILURE",
  DELETE_HARVEST: "DELETE_HARVEST",
  DELETE_HARVEST_COMPLETE: "DELETE_HARVEST_COMPLETE",
  DELETE_HARVEST_FAILURE: "DELETE_HARVEST_FAILURE",
};

//get single harvest
export const setGetSelectedHarvestPending = dispatch => {
  return { type: Types.GET_SELECTED_HARVEST };
}

export const getSelectedHarvestFailure = data => ({
  type: Types.GET_SELECTED_HARVEST_FAILURE,
  data
});

export const getSelectedHarvestComplete = (data) => ({
  type: Types.GET_SELECTED_HARVEST_COMPLETE,
  data
});

export const getSelectedHarvest = (orchardId, harvestId) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetSelectedHarvestPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${orchardId}/harvests/${harvestId}`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getSelectedHarvestComplete(responseJson));
    }
    else {
      dispatch(getSelectedHarvestFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//delete �harvest
export const setDeleteHarvestPending = () => {
  return { type: Types.DELETE_HARVEST };
};

export const deleteHarvestComplete = (id) => {
  return { type: Types.DELETE_HARVEST_COMPLETE, id };
};

export const deleteHarvestFailure = (data) => {
  return { type: Types.DELETE_HARVEST_FAILURE, data };
};

export const deleteHarvest = (harvest, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = `/api/v.1/groves/${orchardId}/harvests/${harvest.id}`;

  dispatch(setDeleteHarvestPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "DELETE",
        headers,
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseText = await response.text();

    if (response.ok) {
      dispatch(deleteHarvestComplete());
      redirect(responseText);
    }
    else {
      dispatch(deleteHarvestFailure(responseText));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get harvests list
export const setGetHarvestsPending = dispatch => {
  return { type: Types.GET_HARVESTS };
}

export const getHarvestsFailure = (data) => ({
  type: Types.GET_HARVESTS_FAILURE,
  data
});

export const getHarvestsComplete = (data) => ({ type: Types.GET_HARVESTS_COMPLETE, data });

export const getHarvests = (guid) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetHarvestsPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${guid}/harvests`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getHarvestsComplete(responseJson));
    }
    else {
      dispatch(getHarvestsFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//create new harvest
export const setCreateNewHarvestPending = () => {
  return { type: Types.CREATE_NEW_HARVEST };
};

export const createNewHarvestComplete = (data) => {
  return { type: Types.CREATE_NEW_HARVEST_COMPLETE, data };
};

export const createNewHarvestFailure = (data) => {
  return { type: Types.CREATE_NEW_HARVEST_FAILURE, data };
};

export const createNewHarvest = (harvest, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = `/api/v.1/groves/${orchardId}/harvests`;

  dispatch(setCreateNewHarvestPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  headers.append("Content-Type", "application/json");

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(harvest),
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseText = await response.text();

    if (response.ok) {
      dispatch(createNewHarvestComplete(responseText));
      redirect(responseText);
    }
    else {
      dispatch(createNewHarvestFailure(responseText));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};


//update harvest
export const setUpdateHarvestPending = dispatch => {
  return { type: Types.UPDATE_HARVEST };
};

export const updateHarvestComplete = (data) => {
  return { type: Types.UPDATE_HARVEST_COMPLETE, data };
};

export const updateHarvestFailure = (data) => {
  return { type: Types.UPDATE_HARVEST_FAILURE, data };
};

export const updateHarvest = (harvest, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = `/api/v.1/groves/${orchardId}/harvests/${harvest.id}`;

  dispatch(setUpdateHarvestPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  headers.append("Content-Type", "application/json");

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify(harvest),
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }
    const responseJson = await response.text();

    if (response.ok) {
      dispatch(updateHarvestComplete(harvest));
      redirect(responseJson);
    }
    else {
      dispatch(updateHarvestFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
