import { preventUnauthorizedRequest } from "../../utils/interceptors";

export const Types = {
  GET_WORK_ACTION_DEFINITIONS: "GET_WORK_ACTION_DEFINITIONS",
  GET_WORK_ACTION_DEFINITIONS_COMPLETE: "GET_WORK_ACTION_DEFINITIONS_COMPLETE",
  GET_WORK_ACTION_DEFINITIONS_FAILURE: "GET_WORK_ACTION_DEFINITIONS_FAILURE",
};

//get work actions definitons
export const setGetWorkActionDefinitionsPending = dispatch => {
  return { type: Types.GET_WORK_ACTION_DEFINITIONS };
}

export const getWorkActionDefinitionsFailure = (data) => ({
  type: Types.GET_WORK_ACTION_DEFINITIONS_FAILURE,
  data
});

export const getWorkActionDefinitionsComplete = (data) => ({ type: Types.GET_WORK_ACTION_DEFINITIONS_COMPLETE, data });

export const getWorkActionDefinitions = () => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetWorkActionDefinitionsPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/workactiondefinitions`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getWorkActionDefinitionsComplete(responseJson));
    }
    else {
      dispatch(getWorkActionDefinitionsFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
