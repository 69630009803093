export const Types = {
  SET_ACCEPT_ALL_COOKIES: "SET_ACCEPT_ALL_COOKIES",
  SET_ACCEPT_NECESSARY_COOKIES: "SET_ACCEPT_NECESSARY_COOKIES",
  SET_ACCEPT_OPTIONAL_COOKIES: "SET_ACCEPT_OPTIONAL_COOKIES",
};

export const acceptAllCookies = () => ({ type: Types.SET_ACCEPT_ALL_COOKIES });

export const acceptNecessaryCookies = () => ({ type: Types.SET_ACCEPT_NECESSARY_COOKIES });

export const acceptOptionalCookies = (options) => ({ type: Types.SET_ACCEPT_OPTIONAL_COOKIES, data: options });
