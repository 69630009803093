const initialState = {
  counties: null,
  loadCountiesPending: false,
  loadCountiesError: null,
  cities: null,
  loadCitiesPending: false,
  loadCitiesError: null,
  settlements: null,
  loadSettlementsPending: false,
  loadSettlementsError: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "GET_COUNTIES":
      return {
        ...state,
        counties: null,
        loadCountiesPending: true,
        loadCountiesError: null
      };

    case "GET_COUNTIES_COMPLETE":
      return {
        ...state,
        counties: action.data,
        loadCountiesPending: false,
        loadCountiesError: null
      };

    case "GET_COUNTIES_FAILURE":
      return {
        ...state,
        counties: null,
        loadCountiesPending: false,
        loadCountiesError: action.data.message
      };

    case "GET_CITIES":
      return {
        ...state,
        cities: null,
        loadCitiesPending: true,
        loadCitiesError: null
      };

    case "GET_CITIES_COMPLETE":
      return {
        ...state,
        cities: action.data,
        loadCitiesPending: false,
        loadCitiesError: null
      };

    case "GET_CITIES_FAILURE":
      return {
        ...state,
        cities: null,
        loadCitiesPending: false,
        loadCitiesError: action.data.message
      };

    case "GET_SETTLEMENTS":
      return {
        ...state,
        settlements: null,
        loadSettlementsPending: true,
        loadSettlementssError: null
      };

    case "GET_SETTLEMENTS_COMPLETE":
      return {
        ...state,
        settlements: action.data,
        loadSettlementsPending: false,
        loadSettlementsError: null
      };

    case "GET_SETTLEMENTS_FAILURE":
      return {
        ...state,
        settlements: null,
        loadSettlementsPending: false,
        loadSettlementsError: action.data.message
      };

    default:
      return state;
  }
}

export const getCounties = (state) => state.counties;
export const getCities = (state) => state.cities;
export const getSettlements = (state) => state.settlements;
