import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as DateHelpers from "../../helpers/date";
import * as UIActions from "../../store/ui/actions";
import * as OrchardWorkActionsSelectors from "../../store/orchardWorkActions/reducer";
import * as WorkActionDefinitionSelectors from '../../store/workActionDefinitions/reducer';
import * as OrchardWorkActions from "../../store/orchardWorkActions/actions";
import { isUserLoggedIn } from "../../store/user/reducer";
import { getSelectedAgent } from "../../store/agents/reducer";
import { getSinglePest } from "../../store/pests/reducer";
import * as AgentActions from "../../store/agents/actions";
import ReactGA from "react-ga";
import "./workActionDetails.scss";

export class WorkActionDetails extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      consent,
      isUserLoggedIn,
      history,
      getSelectedWorkAction,
      setBackButtonRoute,
      match: { params: { orchardId } },
      location: { search },
    } = this.props;

    const params = new URLSearchParams(search);
    const workActionType = params.get('type');

    setBackButtonRoute(`/orchards/${orchardId}`);
    getSelectedWorkAction(workActionType);

    if ((consent || {}).analytics) {
      const page = "/orchards/oid/workactions/wid";
      ReactGA.set({ page, title: "Detalji radova u masliniku" });
      ReactGA.pageview(page);
    }
  }

  redirect = () => {
    const { orchardId } = this.props.match.params;
    this.props.history.replace(`/orchards/${orchardId}`);
  };

  updateHandler = () => {
    const {
      match:
      {
        params:
        {
          orchardId
        }
      },
      workAction,
      history
    } = this.props;
    history.push(`/orchards/${orchardId}/workactions/${workAction.id}/update/${workAction.workActionType}`);
  };

  deleteHandler = () => {
    const { workAction } = this.state;
    const { match: { params: { orchardId } } } = this.props;

    if (!workAction) {
      return false;
    }
    this.props.dispatch(OrchardWorkActions.deleteWorkAction({ ...workAction }, orchardId, this.redirect));
  };

  componentWillUnmount() {
    const { setBackButtonRoute, setActions } = this.props;
    if (setActions)
      setActions(null);
    setBackButtonRoute("");
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      workAction,
      workActionDefinitions,
      setActions
    } = this.props;

    if (!prevState.isTitleSet && workAction && workActionDefinitions) {
      this.setState(state =>
        ({
          isTitleSet: true,
          workAction
        }),
        () => {
          const name = this.getWorkActionName(workAction.workActionDefinitionId);
          document.title = `${name} - Platforma za upravljanje maslinikom`;
          dispatch(UIActions.setPageTitle(name))
        }
      );
    }

    if (!prevProps.workAction && workAction && workAction.appliedAgentId) {
      dispatch(AgentActions.getAgent(workAction.appliedAgentId));
    }

    if (!prevProps.workAction && workAction) {
      if (setActions)
        setActions({
          deleteHandler: this.deleteHandler,
          updateHandler: this.updateHandler,
        });
    }

  }

  state = {
    isTitleSet: false,
    workAction: null,
  };

  getWorkActionIcon = (actionDefinitionId) => {
    const { workActionDefinitions } = this.props;
    return ((workActionDefinitions || []).find(wad => wad.id === actionDefinitionId) || {}).icon;
  };

  getWorkActionName = (actionDefinitionId) => {
    const { workActionDefinitions } = this.props;
    return ((workActionDefinitions || []).find(wad => wad.id === actionDefinitionId) || {}).name;
  };

  render() {
    const { workAction, agent, pest } = this.props;
    console.log("workAction", workAction);

    return (
      <div className="orchard-work-action__wrapper">
        <div className="orchard-work-action__top">
          <div className="orchard-work-action__item-icon">
            {
              workAction && <i className={this.getWorkActionIcon(workAction.workActionDefinitionId)}></i>
            }
          </div>
          <div>
            <div className="orchard-work-action__other">
              <div className="orchard-work-action__label"></div>
              {
                workAction && (
                  <div className="orchard-work-action__data">
                    <span>{`${DateHelpers.stringToDate(workAction.date).getDate()}. ${DateHelpers.numberToMonth(DateHelpers.stringToDate(workAction.date).getMonth() + 1).shortName}, ${DateHelpers.stringToDate(workAction.date).getFullYear()}`}</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className="orchard-work-action__details">
          {workAction && workAction.workActionType === 1 && (
            <>
              {pest && (
                <>
                  <label>Nametnik</label>
                  <p>{pest.name}</p>
                </>
              )
              }

              {agent && (
                <>
                  <label>Korišteno sredstvo</label>
                  <p>{agent.name}</p>
                  <label>Aktivna tvar</label>
                  <p>{agent.activeSubstance}</p>
                  <label>Utrošena količina sredstva</label>
                  <p>{workAction.agentQuantity} {agent.unit}</p>
                  {agent.requiresWater && (
                    <>
                      <label>Utrošena količina vode</label>
                      <p>{workAction.waterQuantity} lit</p>
                    </>
                  )
                  }
                  {workAction.fertilization && (
                    <>
                      <label>Korišteni okvašivači/prihrane</label>
                      <p>{workAction.fertilization}</p>
                    </>
                  )
                  }
                </>
              )
              }
            </>
          )
          }
          < label > Radnih sati</label>
          <p>{workAction && workAction.duration}</p>
          <label>Napomene/Komentari</label>
          <p>{workAction && (workAction.notes || "").split('\n').map(function (item, key) {
            return (
              <Fragment key={key}>
                {item}
                <br />
              </Fragment>
            )
          }
          )}</p>
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => {
  const workAction = OrchardWorkActionsSelectors.getSelectedOrchardWorkAction(state);

  return {
    workAction,
    consent: state.consent,
    workActionDefinitions: WorkActionDefinitionSelectors.getWorkActionDefinitions(state),
    isUserLoggedIn: isUserLoggedIn(state.user),
    agent: getSelectedAgent(state.agents),
    pest: workAction ? getSinglePest(state.pests, workAction.targetPestId) : null,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { orchardId, workActionId } } } = ownProps;
  return {
    dispatch,
    getSelectedWorkAction: (type) => dispatch(OrchardWorkActions.getSelectedOrchardWorkAction(orchardId, workActionId, type)),
    setBackButtonRoute: (route) => dispatch(UIActions.setBackButtonRoute(route)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkActionDetails);
