const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null,
  selectedEntity: null,
  selectedEntityPending: false,
  selectedEntityError: null,
  createNewPending: false,
  createNewFailure: null,
  deleteEntityPending: false,
  deleteEntityError: null,
  updatePending: false,
  updateError: null,
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "GET_ORCHARD_WORK_ACTIONS":
      return {
        ...state,
        loadEntitiesPending: true,
        entities: null,
        loadEntitiesError: null
      };

    case "GET_ORCHARD_WORK_ACTIONS_COMPLETE":
      return {
        ...state,
        entities: action.data,
        loadEntitiesPending: false,
        loadEntitiesError: null
      };

    case "GET_ORCHARD_WORK_ACTIONS_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message
      };

    case "GET_SELECTED_ORCHARD_WORK_ACTION":
      return {
        ...state,
        selectedEntityPending: true,
        selectedEntity: null,
        selectedEntityError: null
      };

    case "GET_SELECTED_ORCHARD_WORK_ACTION_COMPLETE":
      return {
        ...state,
        selectedEntityPending: false,
        selectedEntity: action.data,
        selectedEntityError: null
      };

    case "GET_SELECTED_ORCHARD_WORK_ACTION_FAILURE":
      return {
        ...state,
        selectedEntityPending: false,
        selectedEntity: null,
        selectedEntityError: action.data.message
      };

    case "CREATE_NEW_WORK_ACTION":
      return {
        ...state,
        createNewPending: true,
        createNewFailure: null
      };

    case "CREATE_NEW_WORK_ACTION_COMPLETE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: null
      };


    case "CREATE_NEW_WORK_ACTION_FAILURE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: action.data.message
      };

    case "DELETE_WORK_ACTION":
      return {
        ...state,
        deleteEntityPending: true,
        deleteEntityError: null
      };

    case "DELETE_WORK_ACTION_COMPLETE":
      return {
        ...state,
        deleteEntityPending: false,
        deleteEntityError: null
      };

    case "DELETE_WORK_ACTION_FAILURE":
      return {
        ...state,
        deleteEntityPending: false,
        deleteEntityError: action.data
      };

    case "UPDATE_WORK_ACTION":
      return {
        ...state,
        updatePending: true,
        updateError: null
      };

    case "UPDATE_WORK_ACTION_COMPLETE":
      const elIndex = (state.entities || []).findIndex(item => item.id === action.data.id);

      return {
        ...state,
        selectedEntity: action.data,
        entities: elIndex !== -1 && state.entities ? [...state.entities.slice(0, elIndex), action.data, ...(state.entities.slice(elIndex + 1))] : state.entities,
        updatePending: false,
        updateError: null
      };

    case "UPDATE_WORK_ACTION_FAILURE":
      return {
        ...state,
        updatePending: false,
        updateError: action.data.message
      };

    default:
      return state;
  }
}

export const getOrchardWorkActions = (state) => state.workActions.entities;
export const getSelectedEntityPending = (state) => state.workActions.selectedEntityPending;
export const getSelectedOrchardWorkAction = (state) => state.workActions.selectedEntity;
export const getLoadEntitiesPending = (state) => state.workActions.loadEntitiesPending;
export const getCreateEntityPending = (state) => state.workActions.createNewPending;
export const getUpdateWorkActionPending = (state) => state.workActions.updatePending;
export const getUpdateWorkActionError = (state) => state.workActions.updateError;
