import React, { useState, useLayoutEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import * as DateHelpers from "../../helpers/date";
import * as WorkActionDefinitionSelectors from '../../store/workActionDefinitions/reducer';
import * as OrchardWorkActions from '../../store/orchardWorkActions/actions';
import * as OrchardWorkActionsSelectors from '../../store/orchardWorkActions/reducer';
import { getAllPests } from "../../store/pests/reducer";
import ReactGA from "react-ga";
import "./orchardWorkActionsList.scss";

const OrchardWorkActionsList = ({
  consent,
  selectedOrchard,
  workActions,
  isWorkActionsLoading,
  getWorkActions,
  workActionDefinitions,
  pests,
  history,
}) => {

  const [selectedWadId, setSelectedWad] = useState();

  useLayoutEffect(() => {
    getWorkActions();

    if ((consent || {}).analytics) {
      ReactGA.event({
        category: 'Maslinik',
        action: 'Lista radova'
      });
    }
  }, []);

  function selectWad(id) {
    setSelectedWad(selectedWadId !== id ? id : null);
  };

  const truncateDescription = (text) => (text || "").length > 30 ? `${text.slice(0, 30)}...` : text;

  const getPestById = (id) => {
    return (pests || []).find(p => p.id === id) || {};
  }

  const getWorkActionIcon = (actionDefinitionId) => {
    return (workActionDefinitions.find(wad => wad.id === actionDefinitionId) || {}).icon;
  };

  const getWorkActionName = (actionDefinitionId) => {
    return (workActionDefinitions.find(wad => wad.id === actionDefinitionId) || {}).name;
  };

  const openWorkAction = (orchardId, workAction) => {
    history.push(`/orchards/${orchardId}/workactions/${workAction.id}?type=${workAction.workActionType}`);
  };

  return (
    <>
      <NavLink to={`/orchards/${selectedOrchard.id}/createworkaction`} className="orchard-details__add-new-btn">
        <i className="fas fa-plus"></i>
        {(!workActions || workActions.filter(wa => selectedWadId ? selectedWadId === wa.workActionDefinitionId : true).length < 1) && (
          <div className="orchard-details__add-new-btn-tooltip"><div>Za unos novog zapisa kliknite <i className="fas fa-arrow-right"></i></div></div>
        )}
      </NavLink>
      {(workActions || []).length > 0 && (
        <div className="orchard-details__wads">
          {(workActionDefinitions || []).map(wad =>
            <div
              className={classnames(["orchard-details__wad-item"], { active: wad.id === selectedWadId })}
              key={wad.id}
              onClick={() => selectWad(wad.id)}
            >
              {wad.name}
            </div>
          )
          }
        </div>
      )}
      <div className="orchard-details__work-actions-list">
        {isWorkActionsLoading && <div className="loader"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
        {
          (!isWorkActionsLoading && (!workActions || workActions.filter(wa => selectedWadId ? selectedWadId === wa.workActionDefinitionId : true).length < 1)) && (
            <div className="todo">{selectedWadId && getWorkActionName(selectedWadId)} <br />
              {!selectedWadId && "Dnevnik Vašeg maslinika"}
              <div className="todo-description">
                Još nemate evidentiranih {selectedWadId ? `radova za: ${getWorkActionName(selectedWadId)}` : "radova"}.<br />
                {!selectedWadId && (
                  <p>Počnite bilježiti sadnje, rezibe, zaštite, itd...<br /><br />
                    S vremenom, zapisi postaju vrijedan izvor učenja, ali i uspomene kojih se rado sjeća...</p>
                )}
              </div>
            </div>
          )
        }
        {
          (workActions || []).filter(wa => selectedWadId ? selectedWadId === wa.workActionDefinitionId : true).map((wa, index) => (
            <div className={classnames(["orchard-details__item", { "agent-application-action": wa.workActionType === 1 }])}
              key={index}
              onClick={() => openWorkAction(selectedOrchard.id, wa)}>
              <div className="orchard-details__item-icon">
                <i className={getWorkActionIcon(wa.workActionDefinitionId)}></i>
              </div>
              <div className="orchard-details__item-info-wrapper">
                <div className="orchard-details__item-name">
                  {getWorkActionName(wa.workActionDefinitionId)}
                </div>
                <div className="orchard-details__item-description">{wa.workActionType === 0 ? wa.notes : getPestById(wa.targetPestId).name}</div>
                <div className="orchard-details__item-duration">
                  radnih sati: {(wa.duration || 0) === 0 ? "-" : wa.duration}
                </div>
              </div>
              <div className="orchard-details__item-date">
                <span>{DateHelpers.stringToDate(wa.date).getFullYear()}</span><br />
                {`${DateHelpers.stringToDate(wa.date).getDate()}. ${DateHelpers.numberToMonth(DateHelpers.stringToDate(wa.date).getMonth() + 1).shortName}`}
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
}

const mapStateToProps = (state, props) => ({
  consent: state.consent,
  workActionDefinitions: WorkActionDefinitionSelectors.getWorkActionDefinitions(state) || [],
  pests: getAllPests(state.pests),
  workActions: OrchardWorkActionsSelectors.getOrchardWorkActions(state),
  isWorkActionsLoading: OrchardWorkActionsSelectors.getLoadEntitiesPending(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getWorkActions: () => dispatch(OrchardWorkActions.getOrchardWorkActions(ownProps.match.params.id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrchardWorkActionsList));
