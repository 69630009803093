const initialState = {
  userProfile: null,
  token: null,
  loginPending: false,
  error: null,
  signupPending: false,
  signupError: null,
  generateEmailVerifyCodePending: false,
  generateEmailVerifyCodeError: false,
  confirmEmailAddressPending: false,
  confirmEmailAddressError: false,
  requestPasswordResetPending: false,
  requestPasswordResetFailure: null,
  changePasswordPending: false,
  changePasswordFailure: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "LOGIN":
      return {
        ...state,
        userProfile: null,
        token: null,
        loginPending: true,
        error: null
      };

    case "LOGIN_COMPLETE":
      return {
        ...state,
        userProfile: action.data.user,
        token: action.data.token,
        loginPending: false,
        error: null
      };

    case "LOGIN_FAILURE":
      return {
        ...state,
        userProfile: null,
        token: null,
        loginPending: false,
        error: action.data.message
      };

    case "LOGOUT":
      return {
        ...state,
        userProfile: null,
        token: null,
        loginPending: false,
        error: null
      };

    case "SIGNUP":
      return {
        ...state,
        userProfile: null,
        token: null,
        signupPending: true,
        signupError: null
      };

    case "SIGNUP_COMPLETE":
      return {
        ...state,
        userProfile: action.data.user,
        token: action.data.token,
        signupPending: false,
        signupError: null
      };

    case "SIGNUP_FAILURE":
      return {
        ...state,
        userProfile: null,
        token: null,
        signupPending: false,
        signupError: action.data,
      };

    case "GENERATE_VERIFY_CODE":
      return {
        ...state,
        generateEmailVerifyCodePending: true,
        generateEmailVerifyCodeError: null,
      };

    case "GENERATE_VERIFY_CODE_COMPLETE":
      return {
        ...state,
        generateEmailVerifyCodePending: false,
        generateEmailVerifyCodeError: null,
      };

    case "GENERATE_VERIFY_CODE_FAILURE":
      return {
        ...state,
        generateEmailVerifyCodePending: false,
        generateEmailVerifyCodeError: action.data,
      };

    case "CONFIRM_EMAIL_ADDRESS":
      return {
        ...state,
        confirmEmailAddressPending: true,
        confirmEmailAddressError: null,
      };

    case "CONFIRM_EMAIL_ADDRESS_COMPLETE":
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isEmailConfirmed: true,
        },
        confirmEmailAddressPending: false,
        confirmEmailAddressError: null,
      };

    case "CONFIRM_EMAIL_ADDRESS_FAILURE":
      return {
        ...state,
        confirmEmailAddressPending: false,
        confirmEmailAddressError: action.data,
      };

    case "REQUEST_PASSWORD_RESET":
      return {
        ...state,
        requestPasswordResetPending: true,
        requestPasswordResetFailure: null,
      };

    case "REQUEST_PASSWORD_RESET_COMPLETE":
      return {
        ...state,
        requestPasswordResetPending: false,
        requestPasswordResetFailure: null,
      };

    case "REQUEST_PASSWORD_RESET_FAILURE":
      return {
        ...state,
        requestPasswordResetPending: false,
        requestPasswordResetFailure: action.data,
      };

    case "CHANGE_PASSWORD":
      return {
        ...state,
        changePasswordPending: true,
        changePasswordFailure: null,
      };

    case "CHANGE_PASSWORD_COMPLETE":
      return {
        ...state,
        changePasswordPending: false,
        changePasswordFailure: null,
      };

    case "CHANGE_PASSWORD_FAILURE":
      return {
        ...state,
        changePasswordPending: false,
        changePasswordFailure: action.data,
      };

    default:
      return state;
  }
}

export const isUserLoggedIn = (state) => !!state.token;

export const getUserProfile = (state) => !!state.token ? state.userProfile : null;

export const getToken = (state) => state.token;

export const getVerificationError = (state) => state.confirmEmailAddressError;

export const requestPasswordResetPending = (state) => state.requestPasswordResetPending;

export const requestPasswordResetError = (state) => state.requestPasswordResetFailure;

export const changePasswordPending = (state) => state.changePasswordPending;

export const changePasswordError = (state) => state.changePasswordFailure;
