export const months = [
  { id: 1, name: "Siječnja", shortName: "Sij" },
  { id: 2, name: "Veljače", shortName: "Velj" },
  { id: 3, name: "Ožujka", shortName: "Ožu" },
  { id: 4, name: "Travnja", shortName: "Tra" },
  { id: 5, name: "Svibnja", shortName: "Svi" },
  { id: 6, name: "Lipnja", shortName: "Lip" },
  { id: 7, name: "Srpnja", shortName: "Srp" },
  { id: 8, name: "Kolovoza", shortName: "Kol" },
  { id: 9, name: "Rujna", shortName: "Ruj" },
  { id: 10, name: "Listopada", shortName: "Lis" },
  { id: 11, name: "Studenog", shortName: "Stu" },
  { id: 12, name: "Prosinca", shortName: "Pro" },
];

export const numberToMonth = (number) => months.find(m => m.id === number);

export const stringToDate = (string) => new Date(string);
