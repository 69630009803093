const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null,
  selectedEntity: null,
  selectedEntityPending: false,
  selectedEntityError: null,
  createNewPending: false,
  createNewFailure: null,
  deleteEntityPending: false,
  deleteEntityError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "GET_ORCHARD_NOTES":
      return {
        ...state,
        loadEntitiesPending: true,
        entities: null,
        loadEntitiesError: null
      };

    case "GET_ORCHARD_NOTES_COMPLETE":
      return {
        ...state,
        entities: action.data,
        loadEntitiesPending: false,
        loadEntitiesError: null
      };

    case "GET_ORCHARD_NOTES_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message
      };

    case "GET_SELECTED_ORCHARD_NOTE":
      return {
        ...state,
        selectedEntityPending: true,
        selectedEntity: null,
        selectedEntityError: null
      };

    case "GET_SELECTED_ORCHARD_NOTE_COMPLETE":
      return {
        ...state,
        selectedEntityPending: false,
        selectedEntity: action.data,
        selectedEntityError: null
      };

    case "GET_SELECTED_ORCHARD_NOTE_FAILURE":
      return {
        ...state,
        selectedEntityPending: false,
        selectedEntity: null,
        selectedEntityError: action.data.message
      };

    case "CREATE_NEW_ORCHARD_NOTE":
      return {
        ...state,
        createNewPending: true,
        createNewFailure: null
      };

    case "CREATE_NEW_ORCHARD_NOTE_COMPLETE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: null
      };


    case "CREATE_NEW_ORCHARD_NOTE_FAILURE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: action.data.message
      };

    case "DELETE_NOTE":
      return {
        ...state,
        deleteEntityPending: true,
        deleteEntityError: null
      };

    case "DELETE_NOTE_COMPLETE":
      return {
        ...state,
        deleteEntityPending: false,
        deleteEntityError: null
      };

    case "DELETE_NOTE_FAILURE":
      return {
        ...state,
        deleteEntityPending: false,
        deleteEntityError: action.data
      };

    default:
      return state;
  }
}

export const getOrchardNotes = (state) => state.orchardNotes.entities;
export const getLoadEntitiesPending = (state) => state.orchardNotes.loadEntitiesPending;
export const getSelectedOrchardNote = (state) => state.orchardNotes.selectedEntity;
export const getLoadSelectedEntityPending = (state) => state.orchardNotes.selectedEntityPending;
