const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null,
  selectedEntity: null,
  selectedEntityPending: false,
  selectedEntityError: null,
  createNewPending: false,
  createNewFailure: null,
  updatePending: false,
  updateError: null,
  deleteEntityPending: false,
  deleteEntityError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "GET_HARVESTS":
      return {
        ...state,
        loadEntitiesPending: true,
        entities: null
      };

    case "GET_HARVESTS_COMPLETE":
      return {
        ...state,
        entities: action.data,
        loadEntitiesPending: false,
        loadEntitiesError: null
      };

    case "GET_HARVESTS_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message
      };

    case "GET_SELECTED_HARVEST":
      return {
        ...state,
        selectedEntityPending: true,
        selectedEntity: null,
        selectedEntityError: null
      };

    case "GET_SELECTED_HARVEST_COMPLETE":
      return {
        ...state,
        selectedEntityPending: false,
        selectedEntity: action.data,
        selectedEntityError: null
      };

    case "GET_SELECTED_HARVEST_FAILURE":
      return {
        ...state,
        selectedEntityPending: false,
        selectedEntity: null,
        selectedEntityError: action.data.message
      };

    case "CREATE_NEW_HARVEST":
      return {
        ...state,
        createNewPending: true,
        createNewFailure: null
      };

    case "CREATE_NEW_HARVEST_COMPLETE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: null
      };


    case "CREATE_NEW_HARVEST_FAILURE":
      return {
        ...state,
        createNewPending: false,
        createNewFailure: action.data.message
      };

    case "UPDATE_HARVEST":
      return {
        ...state,
        updatePending: true,
        updateError: null
      };

    case "UPDATE_HARVEST_COMPLETE":
      const elIndex = (state.entities || []).findIndex(item => item.id === action.data.id);

      return {
        ...state,
        selectedEntity: action.data,
        entities: elIndex !== -1 && state.entities ? [...state.entities.slice(0, elIndex), action.data, ...(state.entities.slice(elIndex + 1))] : state.entities,
        updatePending: false,
        updateError: null
      };

    case "UPDATE_HARVEST_FAILURE":
      return {
        ...state,
        updatePending: false,
        updateError: action.data.message
      };

    default:
      return state;
  }
}

export const getLoadEntitiesPending = (state) => state.loadEntitiesPending;
export const getLoadEntitiesErrors = (state) => state.loadEntitiesError;
export const getHarvestsList = (state) => state.entities;

export const getSelectedHarvest = (state) => state.selectedEntity;
export const getSelectedHarvestPending = (state) => state.selectedEntityPending;
export const getSelectedHarvestErrors = (state) => state.selectedEntityError;

export const getCreateNewPending = (state) => state.createNewPending;
export const getCreateNewFailure = (state) => state.getCreateNewFailure;

export const getUpdateHarvestPending = (state) => state.updatePending;
export const getUpdateHarvestError = (state) => state.updateError;

export const getHarvestsSummaryForYear = (state, year) => {
  const listForYear = state.filter(h => new Date(h.harvestDate).getFullYear() === year);

}
