import { preventUnauthorizedRequest } from "../../utils/interceptors";

export const Types = {
  GET_ORCHARDS: "GET_ORCHARDS",
  GET_ORCHARDS_COMPLETE: "GET_ORCHARDS_COMPLETE",
  GET_ORCHARDS_FAILURE: "GET_ORCHARDS_FAILURE",
  GET_SELECTED_ORCHARD: "GET_SELECTED_ORCHARD",
  GET_SELECTED_ORCHARD_COMPLETE: "GET_SELECTED_ORCHARD_COMPLETE",
  GET_SELECTED_ORCHARD_FAILURE: "GET_SELECTED_ORCHARD_FAILURE",
  CREATE_NEW_ORCHARD: "CREATE_NEW_ORCHARD",
  CREATE_NEW_ORCHARD_COMPLETE: "CREATE_NEW_ORCHARD_COMPLETE",
  CREATE_NEW_ORCHARD_FAILURE: "CREATE_NEW_ORCHARD_FAILURE",
  UPDATE_ORCHARD: "UPDATE_ORCHARD",
  UPDATE_ORCHARD_COMPLETE: "UPDATE_ORCHARD_COMPLETE",
  UPDATE_ORCHARD_FAILURE: "UPDATE_ORCHARD_FAILURE",
  DELETE_ORCHARD: "DELETE_ORCHARD",
  DELETE_ORCHARD_COMPLETE: "DELETE_ORCHARD_COMPLETE",
  DELETE_ORCHARD_FAILURE: "DELETE_ORCHARD_FAILURE",
};

//get orchards list
export const setGetOrchardsPending = dispatch => {
  return { type: Types.GET_ORCHARDS };
}

export const getOrchardsFailure = (data) => ({
  type: Types.GET_ORCHARDS_FAILURE,
  data
});

export const getOrchardsComplete = (data) => ({ type: Types.GET_ORCHARDS_COMPLETE, data });

export const getOrchards = () => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetOrchardsPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getOrchardsComplete(responseJson));
    }
    else {
      dispatch(getOrchardsFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get single orchard
export const setGetSelectedOrchardPending = dispatch => {
  return { type: Types.GET_SELECTED_ORCHARD };
}

export const getSelectedOrchardFailure = data => ({
  type: Types.GET_SELECTED_ORCHARD_FAILURE,
  data
});

export const getSelectedOrchardComplete = (data) => ({
  type: Types.GET_SELECTED_ORCHARD_COMPLETE,
  data
});

export const getSelectedOrchard = (guid) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetSelectedOrchardPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${guid}`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getSelectedOrchardComplete(responseJson));
    }
    else {
      dispatch(getSelectedOrchardFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//create new orchard
export const setCreateNewOrchardPending = dispatch => {
  return { type: Types.CREATE_NEW_ORCHARD };
};

export const createNewOrchardComplete = (data) => {
  return { type: Types.CREATE_NEW_ORCHARD_COMPLETE, data };
};

export const createNewOrchardFailure = (data) => {
  return { type: Types.CREATE_NEW_ORCHARD_FAILURE, data };
};

export const createNewOrchard = (orchard, redirect) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setCreateNewOrchardPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  headers.append("Content-Type", "application/json");

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(orchard),
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      dispatch(createNewOrchardFailure({ message: "Unauthorized"}));
      return;
    }
    const responseJson = await response.text();

    if (response.ok) {
      dispatch(createNewOrchardComplete(responseJson));
      redirect(responseJson);
    }
    else {
      dispatch(createNewOrchardFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//update orchard
export const setUpdateOrchardPending = dispatch => {
  return { type: Types.UPDATE_ORCHARD };
};

export const updateOrchardComplete = (data) => {
  return { type: Types.UPDATE_ORCHARD_COMPLETE, data };
};

export const updateOrchardFailure = (data) => {
  return { type: Types.UPDATE_ORCHARD_FAILURE, data };
};

export const updateOrchard = (orchard) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setUpdateOrchardPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  headers.append("Content-Type", "application/json");

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${orchard.id}`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify(orchard),
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }
    const responseJson = await response.text();

    if (response.ok) {
      dispatch(updateOrchardComplete(orchard));
    }
    else {
      dispatch(updateOrchardFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//delete orchard
export const setDeleteOrchardPending = () => {
  return { type: Types.DELETE_ORCHARD };
};

export const deleteOrchardComplete = (id) => {
  return { type: Types.DELETE_ORCHARD_COMPLETE, id };
};

export const deleteOrchardFailure = (data) => {
  return { type: Types.DELETE_ORCHARD_FAILURE, data };
};

export const deleteOrchard = (orchardId, redirect) => async (dispatch, getState) => {

  const result = window.confirm("Jeste li sigurni da želite obrisati maslinik? (Ova akcija je nepovratna.)");
  if (result !== true) {
    return;
  }
  const { user } = getState();
  const apiUrl = `/api/v.1/groves/${orchardId}`;

  dispatch(setDeleteOrchardPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "DELETE",
        headers,
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseText = await response.text();

    if (response.ok) {
      dispatch(deleteOrchardComplete());
      redirect(responseText);
    }
    else {
      dispatch(deleteOrchardFailure(responseText));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};