import React, { useState, useEffect, useLayoutEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom'
import * as UIActions from "../../store/ui/actions";
import * as OrchardActions from '../../store/orchard/actions';
import * as OrchardSelectors from '../../store/orchard/reducer';
import { isUserLoggedIn } from "../../store/user/reducer";
import OrchardWorkActionsList from "../../containers/orchards/orchardWorkActionsList";
import OrchardNotes from "../../containers/orchards/orchardNotes";
import HarvestsList from "../../containers/orchards/harvestsList";
import ReactGA from "react-ga";
import "./orchardDetails.scss";

const OrchardDetails = ({
  consent,
  selectedOrchard,
  isOrchardLoading,
  deleteOrchard,
  deleteEntityPending,
  history,
  location: { search },
  setBackButtonRoute,
  getOrchard,
  setActions,
  setPageTitle,
}) => {
  selectedOrchard = selectedOrchard || {};

  const [currentTab, setCurrentTab] = useState();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const tabId = params.get('tab');

    setCurrentTab(tabId ? parseInt(tabId) : 0);

    if ((consent || {}).analytics) {
      const page = "/orchards/id";
      ReactGA.set({ page, title: "Detalji maslinika" });
      ReactGA.pageview(page);
    }
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    getOrchard();
    setBackButtonRoute("/orchards");

    return () => {
      setBackButtonRoute("");
      if (setActions)
        setActions(null);
    };
  }, []);

  useLayoutEffect(() => {
    if (selectedOrchard.name) {
      document.title = `${selectedOrchard.name} - Platforma za upravljanje maslinikom`;
      setPageTitle(selectedOrchard.name);
    }
  }, [selectedOrchard.name]);

  useEffect(() => {
    if (setActions && selectedOrchard.id) {
      setActions({
        updateHandler: updateHandler,
        deleteHandler: deleteHandler,
      });
    }
  }, [selectedOrchard.id]);

  const updateHandler = () => {
    history.push(`/orchards/${selectedOrchard.id}/update`);
  };

  function redirect() {
    history.push("/orchards");
  };

  function deleteHandler() {
    if (!selectedOrchard) {
      return false;
    }
    deleteOrchard(selectedOrchard.id, redirect);
  };
  return (
    <div className="orchard-details__wrapper">
      {!isOrchardLoading && (
        <div className="orchard-details__top-wrapper">
          {/*<div className="orchard-details__top-wrapper-toggle">
            <i class="fas fa-info-circle"></i>
          </div>*/}
          <div className="orchard-details__top">
            <div className="orchard-details__other">
              <div className="orchard-details__label">lokacija</div>
              {selectedOrchard.settlementName && (
                <div className="orchard-details__data">
                  {selectedOrchard.settlementName}
                </div>
              )
              }

              {!selectedOrchard.settlementName && (
                <button
                  onClick={updateHandler}
                  className="orchard-details__update"
                >
                  Odaberite naselje
                  </button>
              )
              }
            </div>
            <div className="orchard-details__other">
              <div className="orchard-details__label">površina</div>
              <div className="orchard-details__data">
                {selectedOrchard.area} <span className="area-unit">m<span className="area-unit-superscript">2</span></span>
              </div>
            </div>
            <div className="orchard-details__other">
              <div className="orchard-details__label">stabala</div>
              <div className="orchard-details__data">
                {selectedOrchard.numberOfTrees || "-"}
              </div>
            </div>
          </div>
          <div className="orchard-details__sortiment">
            {(selectedOrchard.sortiment || []).map(s =>
              <div key={s} className="orchard-details__sort">{s}</div>
            )}
          </div>
        </div>
      )}
      <div className="orchard-details__tabs">
        <div className={classnames("orchard-details__item", { active: currentTab === 0 })} onClick={() => setCurrentTab(0)}>Radovi</div>
        <div className={classnames("orchard-details__item", { active: currentTab === 1 })} onClick={() => setCurrentTab(1)}>Zapažanja</div>
        <div className={classnames("orchard-details__item", { active: currentTab === 2 })} onClick={() => setCurrentTab(2)}>Berbe</div>
      </div>
      {
        currentTab === 0 && <OrchardWorkActionsList selectedOrchard={selectedOrchard} />
      }
      {
        currentTab === 1 && <OrchardNotes selectedOrchard={selectedOrchard} />
      }
      {
        currentTab === 2 && <HarvestsList selectedOrchard={selectedOrchard} />
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  consent: state.consent,
  selectedOrchard: state.orchards.selectedEntity,
  isOrchardLoading: OrchardSelectors.getSelectedEntityPending(state),
  deleteEntityPending: OrchardSelectors.getDeleteEntityPending(state),
  isUserLoggedIn: isUserLoggedIn(state.user),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getOrchard: () => dispatch(OrchardActions.getSelectedOrchard(ownProps.match.params.id)),
  deleteOrchard: (orchardId, redirect) => dispatch(OrchardActions.deleteOrchard(orchardId, redirect)),
  setBackButtonRoute: (route) => dispatch(UIActions.setBackButtonRoute(route)),
  setPageTitle: (title) => dispatch(UIActions.setPageTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrchardDetails);
