import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as UIActions from "../../store/ui/actions";
import { logoutUser } from "../../store/user/actions";
import { isUserLoggedIn, getUserProfile } from "../../store/user/reducer";
import "./navigation.scss";

export class Navigation extends PureComponent {

  state = {
    menuExpanded: false,
  };

  toggleMenu = (title) => {
    title && this.props.dispatch(UIActions.setPageTitle(title));
    this.setState((prevState) => ({
      menuExpanded: !prevState.menuExpanded
    })
    );
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  logout = () => {
    this.toggleMenu();
    this.props.dispatch(logoutUser());
  }

  componentWillUnmount() {
    this.setState({ menuExpanded: false });
    console.log("nav unmount");
  }

  render() {

    const {
      title,
      backButtonRoute,
      location: { pathname },
      isUserLoggedIn,
      userProfile,
      onDelete,
      onUpdate,
    } = this.props;
    const { menuExpanded } = this.state;

    console.log("menu", menuExpanded);

    if (["/login", "/register", "/home", "/cookies", "/terms", "/resetpassword", "/"].includes(pathname)
      || pathname.includes("resetpassword"))
      return null;

    return (
      <>
        <div className="navigation__wrapper">
          {!backButtonRoute && (
            <div
              className="navigation-panel__item-icon-wrapper"
              onClick={() => this.toggleMenu()}
            >
              <i className={classnames({ "fas fa-times": menuExpanded }, { "fas fa-bars": !menuExpanded })}></i>
            </div>
          )
          }
          {backButtonRoute && <NavLink to={backButtonRoute}>
            <div className="navigation-panel__item-icon-wrapper"><i className="fas fa-chevron-left"></i></div></NavLink>}
          <div className="navigation-panel__title">{title}</div>
          <div className="navigation-panel__actions">
            {onUpdate && (
              <div
                className="navigation-panel__item-icon-wrapper"
                onClick={() => onUpdate()}
              >
                <i className="far fa-edit"></i>
              </div>
            )}
            {onDelete && (
              <div
                className="navigation-panel__item-icon-wrapper"
                onClick={() => onDelete()}
              >
                <i className="far fa-trash-alt"></i>
              </div>
            )}
          </div>
        </div>
        <div className={classnames(["navigation-panel", { expanded: this.state.menuExpanded }])}>
          <div
            className="navigation-panel__background"
            onClick={() => this.toggleMenu()}
          >
          </div>
          <div className="navigation-panel__header-wrapper">
            {isUserLoggedIn && (
              <>
                <div className="navigation-panel__header-user-email">
                  <i className="fas fa-user"></i> {(userProfile || {}).email}
                </div>
                {!(userProfile || {}).isEmailConfirmed && (
                  <NavLink
                    to="/verify-email"
                    onClick={() => this.toggleMenu()}
                    className="navigation-panel__verify-email"
                  >
                    Verifikacija email adrese >
                </NavLink>
                )}
              </>
            )
            }
          </div>
          <div className="navigation-panel__items-wrapper">
            {isUserLoggedIn && <NavLink
              to="/orchards"
              activeClassName="isActive"
              onClick={() => this.toggleMenu()}
            >
              <div className="navigation-panel__item">
                Moji maslinici
              <div className="navigation-panel__item-description">Dnevnik radova, zapažanja, komentari...</div>
              </div>
            </NavLink>
            }
            <NavLink
              to="/pests"
              activeClassName="isActive"
              onClick={() => this.toggleMenu("Sredstva za zaštitu")}
            >
              <div className="navigation-panel__item">
                Zaštitna sredstva
              <div className="navigation-panel__item-description">Katalog certificiranih zaštitnih sredstava</div>
              </div>
            </NavLink>
            <NavLink
              to="/guide"
              activeClassName="isActive"
              onClick={() => this.toggleMenu("Vodič (u pripremi)")}
            >
              <div className="navigation-panel__item">
                Osnove maslinarstva
              <div className="navigation-panel__item-description">Sortiment, sadnja, rezidba, zaštita...</div>
              </div>
            </NavLink>
            <NavLink
              to="/advices"
              activeClassName="isActive"
              onClick={() => this.toggleMenu("Iz Savjetodavne službe")}
            >
              <div className="navigation-panel__item">
                Iz Savjetodavne službe
              <div className="navigation-panel__item-description">Upozorenja, savjeti...</div>
              </div>
            </NavLink>
            <NavLink
              to="/support"
              activeClassName="isActive"
              onClick={() => this.toggleMenu("Podrška")}
            >
              <div className="navigation-panel__item highlighted">
                Korisnička podrška
              <div className="navigation-panel__item-description">Pohvale, kritike, prijave problema...</div>
              </div>
            </NavLink>
            {/*<NavLink
              to="/processing"
              activeClassName="isActive"
              onClick={() => this.toggleMenu("Iz Savjetodavne službe")}
            >
              <div className="navigation-panel__item">
                Popis uljara
              <div className="navigation-panel__item-description">Svi putevi vode u uljaru...</div>
              </div>
            </NavLink>*/}
            {isUserLoggedIn && (
              <div className="navigation-panel__item-signout">
                <div onClick={this.logout}>Odjava</div>
              </div>
            )
            }
            {!isUserLoggedIn && (
              <div className="navigation-panel__item-signout">
                <NavLink to="/login">
                  <div>
                    Prijava/Registracija
              </div>
                </NavLink>
              </div>
            )
            }
          </div>
        </div>
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  title: state.ui.pageTitle,
  backButtonRoute: state.ui.backButtonRoute,
  isUserLoggedIn: isUserLoggedIn(state.user),
  userProfile: getUserProfile(state.user),
});

export const mapDispatchToProps = (dispatch) => ({ dispatch });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
