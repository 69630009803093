import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import * as Storage from "../utils/localStorage";
import orchardReducer from './orchard/reducer';
import uiReducer from "./ui/reducer";
import pestsReducer from "./pests/reducer";
import agentsReducer from "./agents/reducer";
import userReducer from "./user/reducer";
import workActionsReducer from "./orchardWorkActions/reducer";
import workActionDefinitionsReducer from "./workActionDefinitions/reducer";
import orchardNotesReducer from "./orchardNotes/reducer";
import harvestsReducer from "./harvests/reducer";
import consentReducer from "./consent/reducer";
import supportReducer from "./support/reducer";
import advicesReducer from "./advices/reducer";
import administrativeUnitsReducer from "./administrativeUnits/reducer";

const composeEnhancers = process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const logger = ({ dispatch, getState }) => next => action => {
  console.log('will dispatch', action);

  // Call the next dispatch method in the middleware chain.
  const returnValue = next(action);

  console.log('state after dispatch', getState());

  // This will likely be the action itself, unless
  // a middleware further in chain changed it.
  return returnValue;
}

const middleware = applyMiddleware(thunk/*, logger*/);

const reducer = combineReducers({
  orchards: orchardReducer,
  ui: uiReducer,
  pests: pestsReducer,
  agents: agentsReducer,
  user: userReducer,
  workActions: workActionsReducer,
  workActionDefinitions: workActionDefinitionsReducer,
  orchardNotes: orchardNotesReducer,
  harvests: harvestsReducer,
  consent: consentReducer,
  support: supportReducer,
  advices: advicesReducer,
  administrativeUnits: administrativeUnitsReducer,
})

//set user if exists in localstorage
let userAuthData = Storage.getUserAuth();
let userInitialState = {
  userProfile: (userAuthData || {}).user,
  token: (userAuthData || {}).token,
  loginPending: false,
  error: null
};

//cookies consent state
const consentCookie = readCookie("consent");

export default createStore(
  reducer,
  {
    user: userInitialState,
    ...(consentCookie && { consent: JSON.parse(consentCookie) }),
  },
  composeEnhancers(middleware)
);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}