const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "GET_WORK_ACTION_DEFINITIONS":
      return {
        ...state,
        loadEntitiesPending: true,
        entities: null,
        loadEntitiesError: null
      };

    case "GET_WORK_ACTION_DEFINITIONS_COMPLETE":
      return {
        ...state,
        entities: action.data,
        loadEntitiesPending: false,
        loadEntitiesError: null
      };

    case "GET_WORK_ACTION_DEFINITIONS_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message
      };

    default:
      return state;
  }
}

export const getWorkActionDefinitions = (state) => state.workActionDefinitions.entities;
