const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null,
  entitiesPagination: { token: null, hasMoreData: false },
  selectedEntity: null,
  selectedEntityPending: false,
  selectedEntityError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ADVICES":
      return {
        ...state,
        loadEntitiesPending: true,
        loadEntitiesError: null,
        entitiesPagination: {
          ...state.entitiesPagination,
          token: null,
          hasMoreData: null,
        },
      };

    case "GET_ADVICES_COMPLETE":
      return {
        ...state,
        entities: [...(state.entities || []), ...(action.data.data || [])],
        loadEntitiesPending: false,
        loadEntitiesError: null,
        entitiesPagination: {
          ...state.entitiesPagination,
          token: action.data.token,
          hasMoreData: action.data.hasMoreData,
        },
      };

    case "GET_ADVICES_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message,
        entitiesPagination: {
          ...state.entitiesPagination,
          token: null,
          hasMoreData: false,
        },
      };

    case "GET_SELECTED_ADVICE":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: true,
        selectedEntityError: null
      };

    case "GET_SELECTED_ADVICE_COMPLETE":
      return {
        ...state,
        selectedEntity: action.data,
        selectedEntityPending: false,
        selectedEntityError: null
      };

    case "GET_SELECTED_ADVICE_FAILURE":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: false,
        selectedEntityError: action.data
      };

    default:
      return state;
  }
}

export const getAdvices = (state) => state.entities;
export const getPagination = (state) => state.entitiesPagination;
export const getAdvice = (state) => state.selectedEntity;
