const initialState = {
  sendTicketPending: false,
  sendTicketFailure: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "SEND_TICKET":
      return {
        ...state,
        sendTicketPending: true,
        sendTicketFailure: null
      };

    case "SEND_TICKET_COMPLETE":
      return {
        ...state,
        sendTicketPending: false,
        sendTicketFailure: null
      };


    case "SEND_TICKET_FAILURE":
      return {
        ...state,
        sendTicketPending: false,
        sendTicketFailure: action.data.message
      };

    default:
      return state;
  }
}

export const getSendTicketPending = (state) => state.support.sendTicketPending;
