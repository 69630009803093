import { preventUnauthorizedRequest } from "../../utils/interceptors";

export const Types = {
  GET_ORCHARD_NOTES: "GET_ORCHARD_NOTES",
  GET_ORCHARD_NOTES_COMPLETE: "GET_ORCHARD_NOTES_COMPLETE",
  GET_ORCHARD_NOTES_FAILURE: "GET_ORCHARD_NOTES_FAILURE",
  CREATE_NEW_ORCHARD_NOTE:"CREATE_NEW_ORCHARD_NOTE",
  CREATE_NEW_ORCHARD_NOTE_COMPLETE: "CREATE_NEW_ORCHARD_NOTE_COMPLETE",
  CREATE_NEW_ORCHARD_NOTE_FAILURE: "CREATE_NEW_ORCHARD_NOTE_FAILURE",
  GET_SELECTED_ORCHARD_NOTE: "GET_SELECTED_ORCHARD_NOTE",
  GET_SELECTED_ORCHARD_NOTE_COMPLETE: "GET_SELECTED_ORCHARD_NOTE_COMPLETE",
  GET_SELECTED_ORCHARD_NOTE_FAILURE: "GET_SELECTED_ORCHARD_NOTE_FAILURE",
  DELETE_NOTE: "DELETE_NOTE",
  DELETE_NOTE_COMPLETE: "DELETE_NOTE_COMPLETE",
  DELETE_NOTE_FAILURE: "DELETE_NOTE_FAILURE",
};

//get orchards notes list
export const setGetOrchardNotesPending = () => {
  return { type: Types.GET_ORCHARD_NOTES };
}

export const getOrchardNotesFailure = (data) => ({
  type: Types.GET_ORCHARD_NOTES_FAILURE,
  data
});

export const getOrchardNotesComplete = (data) => ({ type: Types.GET_ORCHARD_NOTES_COMPLETE, data });

export const getOrchardNotes = (guid) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetOrchardNotesPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${guid}/notes`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getOrchardNotesComplete(responseJson));
    }
    else {
      dispatch(getOrchardNotesFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//create new note
export const setCreateNewNotePending = () => {
  return { type: Types.CREATE_NEW_ORCHARD_NOTE };
};

export const createNewNoteComplete = (data) => {
  return { type: Types.CREATE_NEW_ORCHARD_NOTE_COMPLETE, data };
};

export const createNewNoteFailure = (data) => {
  return { type: Types.CREATE_NEW_ORCHARD_NOTE_FAILURE, data };
};

export const createNewNote = (note, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = `/api/v.1/groves/${orchardId}/notes`;

  dispatch(setCreateNewNotePending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  headers.append("Content-Type", "application/json");

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(note),
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseText = await response.text();

    if (response.ok) {
      dispatch(createNewNoteComplete(responseText));
      redirect(responseText);
    }
    else {
      dispatch(createNewNoteFailure(responseText));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get single orchard note
export const setGetSelectedOrchardNotePending = dispatch => {
  return { type: Types.GET_SELECTED_ORCHARD_NOTE };
}

export const getSelectedOrchardNoteFailure = data => ({
  type: Types.GET_SELECTED_ORCHARD_NOTE_FAILURE,
  data
});

export const getSelectedOrchardNoteComplete = (data) => ({
  type: Types.GET_SELECTED_ORCHARD_NOTE_COMPLETE,
  data
});

export const getSelectedOrchardNote = (orchardId, noteId) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetSelectedOrchardNotePending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${orchardId}/notes/${noteId}`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getSelectedOrchardNoteComplete(responseJson));
    }
    else {
      dispatch(getSelectedOrchardNoteFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//delete work action
export const setDeleteOrchardNotePending = () => {
  return { type: Types.DELETE_NOTE };
};

export const deleteOrchardNoteComplete = (id) => {
  return { type: Types.DELETE_NOTE_COMPLETE, id };
};

export const deleteOrchardNoteFailure = (data) => {
  return { type: Types.DELETE_NOTE_FAILURE, data };
};

export const deleteOrchardNote = (note, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = `/api/v.1/groves/${orchardId}/notes/${note.id}`;

  dispatch(setDeleteOrchardNotePending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "DELETE",
        headers,
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseText = await response.text();

    if (response.ok) {
      dispatch(deleteOrchardNoteComplete());
      redirect(responseText);
    }
    else {
      dispatch(deleteOrchardNoteFailure(responseText));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
