const initialState = {
  pageTitle: "",
  backButtonRoute: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PAGE_TITLE":
      return { ...state, pageTitle: action.data };

    case "SET_BACK_BUTTON_ROUTE":
      return { ...state, backButtonRoute: action.data };
    default:
      return state;
  }
}