const USER_AUTH_DATA = "USER_AUTH_DATA";

export const setUserAuth = (userAuthData) => {
  let userAuthDataString = JSON.stringify(userAuthData);
  localStorage.setItem(USER_AUTH_DATA, userAuthDataString);
}

export const getUserAuth = () => {
  let userAuthDataString = localStorage.getItem(USER_AUTH_DATA);
  if (!userAuthDataString) {
    return null;
  }

  return JSON.parse(userAuthDataString);
}

export const removeUserAuth = () => {
  localStorage.removeItem(USER_AUTH_DATA);
};
