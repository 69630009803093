import React, { Component } from "react";
import { connect } from "react-redux";
import History from "./utils/history";
import * as UserActions from "./store/user/actions";
import { isUserLoggedIn } from "./store/user/reducer";
import * as WorkActionDefinitionActions from './store/workActionDefinitions/actions';
import * as AdministrativeUnitsActions from "./store/administrativeUnits/actions";
import * as PestActions from "./store/pests/actions";
import CookieConsent from "./containers/legal/consent";
import { isCrawler } from "./helpers/useragent";
import ReactGA from 'react-ga';

class InitializeApp extends Component {

  constructor(props) {
    super(props);

    const { consent } = props;
    console.log(props);

    if ((consent || {}).analytics) {
      console.log("We already have consent");
      this.initializeReactGA();
      this.state = { reactGAInitialized: true };
    }
  }

  state = {
    reactGAInitialized: false,
  };

  initializeReactGA() {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize('UA-141734261-1');
    }
  }

  componentDidMount() {
    const { consent } = this.props;
    this.entryPoint();
  }

  componentDidUpdate() {
    const { consent } = this.props;
    const { reactGAInitialized } = this.state;
    if ((consent || {}).analytics && !reactGAInitialized) {
      this.initializeReactGA();
    }
  }

  entryPoint() {
    console.log("env: ", process.env.NODE_ENV, process.env.REACT_APP_API_URL);
    this.props.dispatch(WorkActionDefinitionActions.getWorkActionDefinitions());
    this.props.dispatch(PestActions.getPests());
    this.props.dispatch(AdministrativeUnitsActions.getCounties());
  }

  render() {
    console.log("crawler: ", isCrawler());
    return (
      <>
        {!isCrawler() && <CookieConsent />}
        {this.props.children}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  consent: state.consent,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(InitializeApp);
