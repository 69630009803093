const initialState = {
  entities: null,
  loadEntitiesPending: false,
  loadEntitiesError: null,
  selectedEntity: null,
  selectedEntityPending: false,
  selectedEntityError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case "GET_PESTS":
      return {
        ...state,
        loadEntitiesPending: true,
      };

    case "GET_PESTS_COMPLETE":
      return {
        ...state,
        entities: action.data,
        loadEntitiesPending: false,
        loadEntitiesError: null,
      };

    case "GET_PESTS_FAILURE":
      return {
        ...state,
        entities: null,
        loadEntitiesPending: false,
        loadEntitiesError: action.data.message,
      };

    case "GET_SELECTED_PEST":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: true,
        selectedEntityError: null
      };

    case "GET_SELECTED_PEST_COMPLETE":
      return {
        ...state,
        selectedEntity: action.data,
        selectedEntityPending: false,
        selectedEntityError: null
      };

    case "GET_SELECTED_PEST_FAILURE":
      return {
        ...state,
        selectedEntity: null,
        selectedEntityPending: false,
        selectedEntityError: action.data
      };

    default:
      return state;
  }
}

export const getAllPests = (state) => state.entities;

export const getSinglePest = (state, id) => (state.entities || []).find(p => p.id === id);

export const getSelectedPest = (state) => state.selectedEntity;
