import { preventUnauthorizedRequest } from "../../utils/interceptors";

export const Types = {
  GET_ORCHARD_WORK_ACTIONS: "GET_ORCHARD_WORK_ACTIONS",
  GET_ORCHARD_WORK_ACTIONS_COMPLETE: "GET_ORCHARD_WORK_ACTIONS_COMPLETE",
  GET_ORCHARD_WORK_ACTIONS_FAILURE: "GET_ORCHARD_WORK_ACTIONS_FAILURE",
  CREATE_NEW_WORK_ACTION: "CREATE_NEW_WORK_ACTION",
  CREATE_NEW_WORK_ACTION_COMPLETE: "CREATE_NEW_WORK_ACTION_COMPLETE",
  CREATE_NEW_WORK_ACTION_FAILURE: "CREATE_NEW_WORK_ACTION_FAILURE",
  GET_SELECTED_ORCHARD_WORK_ACTION: "GET_SELECTED_ORCHARD_WORK_ACTION",
  GET_SELECTED_ORCHARD_WORK_ACTION_COMPLETE: "GET_SELECTED_ORCHARD_WORK_ACTION_COMPLETE",
  GET_SELECTED_ORCHARD_WORK_ACTION_FAILURE: "GET_SELECTED_ORCHARD_WORK_ACTION_FAILURE",
  DELETE_WORK_ACTION: "DELETE_WORK_ACTION",
  DELETE_WORK_ACTION_COMPLETE: "DELETE_WORK_ACTION_COMPLETE",
  DELETE_WORK_ACTION_FAILURE: "DELETE_WORK_ACTION_FAILURE",
  UPDATE_WORK_ACTION: "UPDATE_WORK_ACTION",
  UPDATE_WORK_ACTION_COMPLETE: "UPDATE_WORK_ACTION_COMPLETE",
  UPDATE_WORK_ACTION_FAILURE: "UPDATE_WORK_ACTION_FAILURE",
};

//get orchards work actions list
export const setGetOrchardWorkActionsPending = () => {
  return { type: Types.GET_ORCHARD_WORK_ACTIONS };
}

export const getOrchardWorkActionsFailure = (data) => ({
  type: Types.GET_ORCHARD_WORK_ACTIONS_FAILURE,
  data
});

export const getOrchardWorkActionsComplete = (data) => ({ type: Types.GET_ORCHARD_WORK_ACTIONS_COMPLETE, data });

export const getOrchardWorkActions = (guid) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetOrchardWorkActionsPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${guid}/all-actions`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getOrchardWorkActionsComplete(responseJson));
    }
    else {
      dispatch(getOrchardWorkActionsFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//create new work action
export const setCreateNewWorkActionPending = () => {
  return { type: Types.CREATE_NEW_WORK_ACTION };
};

export const createNewWorkActionComplete = (data) => {
  return { type: Types.CREATE_NEW_WORK_ACTION_COMPLETE, data };
};

export const createNewWorkActionFailure = (data) => {
  return { type: Types.CREATE_NEW_WORK_ACTION_FAILURE, data };
};

export const createNewWorkAction = (workAction, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = workAction.workActionType === 0 ? `/api/v.1/groves/${orchardId}/actions` : `/api/v.1/groves/${orchardId}/ap-actions`;

  dispatch(setCreateNewWorkActionPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  headers.append("Content-Type", "application/json");

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(workAction),
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseText = await response.text();

    if (response.ok) {
      dispatch(createNewWorkActionComplete(responseText));
      redirect(responseText);
    }
    else {
      dispatch(createNewWorkActionFailure(responseText));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get single orchard work action
export const setGetSelectedOrchardWorkActionPending = dispatch => {
  return { type: Types.GET_SELECTED_ORCHARD_WORK_ACTION };
}

export const getSelectedOrchardWorkActionFailure = data => ({
  type: Types.GET_SELECTED_ORCHARD_WORK_ACTION_FAILURE,
  data
});

export const getSelectedOrchardWorkActionComplete = (data) => ({
  type: Types.GET_SELECTED_ORCHARD_WORK_ACTION_COMPLETE,
  data
});

export const getSelectedOrchardWorkAction = (orchardId, workActionId, type) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetSelectedOrchardWorkActionPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  const actionsPart = type === "0" ? "actions" : "ap-actions";
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/groves/${orchardId}/${actionsPart}/${workActionId}`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getSelectedOrchardWorkActionComplete(responseJson));
    }
    else {
      dispatch(getSelectedOrchardWorkActionFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//delete work action
export const setDeleteWorkActionPending = () => {
  return { type: Types.DELETE_WORK_ACTION };
};

export const deleteWorkActionComplete = (id) => {
  return { type: Types.DELETE_WORK_ACTION_COMPLETE, id };
};

export const deleteWorkActionFailure = (data) => {
  return { type: Types.DELETE_WORK_ACTION_FAILURE, data };
};

export const deleteWorkAction = (workAction, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = workAction.workActionType === 0 ? `/api/v.1/groves/${orchardId}/actions/${workAction.id}` : `/api/v.1/groves/${orchardId}/ap-actions/${workAction.id}`;

  dispatch(setDeleteWorkActionPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "DELETE",
        headers,
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseText = await response.text();

    if (response.ok) {
      dispatch(deleteWorkActionComplete());
      redirect(responseText);
    }
    else {
      dispatch(deleteWorkActionFailure(responseText));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//update workaction
export const setUpdateWorkActionPending = dispatch => {
  return { type: Types.UPDATE_WORK_ACTION };
};

export const updateWorkActionComplete = (data) => {
  return { type: Types.UPDATE_WORK_ACTION_COMPLETE, data };
};

export const updateWorkActionFailure = (data) => {
  return { type: Types.UPDATE_WORK_ACTION_FAILURE, data };
};

export const updateWorkAction = (workAction, orchardId, redirect) => async (dispatch, getState) => {
  const { user } = getState();
  const apiUrl = workAction.workActionType === 0 ? `/api/v.1/groves/${orchardId}/actions/${workAction.id}` : `/api/v.1/groves/${orchardId}/ap-actions/${workAction.id}`;

  dispatch(setUpdateWorkActionPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }
  headers.append("Content-Type", "application/json");

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiUrl}`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify(workAction),
        mode: "cors"
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }
    const responseJson = await response.text();

    if (response.ok) {
      dispatch(updateWorkActionComplete(workAction));
      redirect(responseJson);
    }
    else {
      dispatch(updateWorkActionFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
