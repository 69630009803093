import { preventUnauthorizedRequest } from "../../utils/interceptors";

export const Types = {
  GET_AGENTS: "GET_AGENTS",
  GET_AGENTS_COMPLETE: "GET_AGENTS_COMPLETE",
  GET_AGENTS_FAILURE: "GET_AGENTS_FAILURE",
  GET_SELECTED_AGENT: "GET_SELECTED_AGENT",
  GET_SELECTED_AGENT_COMPLETE: "GET_SELECTED_AGENT_COMPLETE",
  GET_SELECTED_AGENT_FAILURE: "GET_SELECTED_AGENT_FAILURE",
  CLEAR_AGENTS: "CLEAR_AGENTS",
};

//clear agents list
export const clearAgents = dispatch => {
  return { type: Types.CLEAR_AGENTS };
}

//get agents list
export const setGetAgentsPending = dispatch => {
  return { type: Types.GET_AGENTS };
}

export const getAgentsComplete = (data) => ({ type: Types.GET_AGENTS_COMPLETE, data });

export const getAgentsFailure = (data) => ({
  type: Types.GET_AGENTS_FAILURE,
  data
});

export const getAgents = (pestId) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetAgentsPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/agents?pestId=${pestId}`,
      {
        method: "GET",
        headers
      });

    //response = preventUnauthorizedRequest(response);
    //if (!response) {
    //  return;
    //}

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getAgentsComplete(responseJson));
    }
    else {
      dispatch(getAgentsFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get single agent
export const setGetSelectedAgentPending = dispatch => {
  return { type: Types.GET_SELECTED_AGENT };
};

export const getSelectedAgentComplete = (data) => ({
  type: Types.GET_SELECTED_AGENT_COMPLETE,
  data
});

export const getSelectedAgentFailure = data => ({
  type: Types.GET_SELECTED_AGENT_FAILURE,
  data
});

export const getAgent = (guid) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetSelectedAgentPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/agents/${guid}`,
      {
        method: "GET",
        headers
      });

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getSelectedAgentComplete(responseJson));
    }
    else {
      dispatch(getSelectedAgentFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
