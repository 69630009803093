import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import * as ConsentActions from "../../store/consent/actions";
import "./consent.scss";

const CookieConsent = ({
  consent,
  acceptNecessaryCookies,
  acceptAllCookies,
  acceptOptionalCookies,
}) => {

  const [showOptions, changeShowOptions] = useState(false);
  const [options, changeOptions] = useState({ technical: true, functional: false, analytics: false });

  function handleCheckbox(event) {
    const target = event.target;
    const name = target.name;
    const value = event.target.checked;

    changeOptions(prevState => ({ ...options, [name]: value }));
  }

  useEffect(() => {
    if ((consent || {}).consentGiven) {
      var expiration_date = new Date();
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);

      document.cookie = `consent=${JSON.stringify(consent)}; path=/; expires=${expiration_date.toUTCString()}`;
    }
  }, [consent]);

  function handleConsent(consentType) {
    switch (consentType) {
      case "necessary":
        acceptNecessaryCookies();
        break;

      case "all":
        acceptAllCookies();
        break;

      case "chosen":
        acceptOptionalCookies(options);
        break;

      default:
        break;
    };
  }

  return (consent || {}).consentGiven || (["/cookies", "/terms"].includes(window.location.pathname))
    ? null
    : (
      <div className="cookie-consent">
        <div className="cookie-consent__text">
          <h3>
            Naše mrežno mjesto koristi kolačiće u svrhu poboljšanja korisničkog iskustva te za analizu prometa.
        </h3>
          <p>
            <b>Neophodni kolačići</b> su nužni za rad našeg mrežnog mjesta i korištenje njegovih značajki i /ili usluga.
          </p>
          <p>
            <b>Ostali kolačići</b> (funkcionalni i analitički) obuhvaćaju pružanje najboljeg korisničkog iskustva, analizu posjeta
            mrežnog mjesta u svrhu statistike te poboljšanja naših usluga.
            </p>
          <p>
            Za više informacija ili promjene postavki posjetite <a href="/cookies">Pravila o kolačićima</a>
          </p>



          <div className="cookie-consent__buttons">
            <button className="some" onClick={() => handleConsent("necessary")}>Prihvaćam samo neophodne kolačiće</button>
            <button className="all" onClick={() => handleConsent("all")}>Prihvaćam sve kolačiće</button>
          </div>


          <div className="options" onClick={() => changeShowOptions(prevOptions => !prevOptions)}>> Dodatne opcije</div>
          {showOptions && (
            <div className="more-options">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <input
                        name="technical"
                        type="checkbox"
                        checked={options.technical}
                        onChange={handleCheckbox}
                        disabled /></td>
                    <td>Tehnički (neophodni) </td>
                    <td><a href="/cookies">Više informacija</a></td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        name="functional"
                        type="checkbox"
                        checked={options.functional}
                        onChange={handleCheckbox}
                      />
                    </td>
                    <td>Funkctionalni</td>
                    <td><a href="/cookies">Više informacija</a></td>
                  </tr>

                  <tr>
                    <td>
                      <input
                        name="analytics"
                        type="checkbox"
                        checked={options.analytics}
                        onChange={handleCheckbox}
                      />
                    </td>
                    <td>Analitički</td>
                    <td><a href="/cookies">Više informacija</a></td>
                  </tr>
                </tbody>
              </table>
              <div className="chosen" onClick={() => handleConsent("chosen")}>Prihvaćam odabrane kolačiće</div>
            </div>
          )}

        </div>
      </div>
    );
}

const mapStateToProps = (state) => ({
  consent: state.consent,
});

const mapDispatchToProps = (dispatch) => ({
  acceptAllCookies: () => dispatch(ConsentActions.acceptAllCookies()),
  acceptNecessaryCookies: () => dispatch(ConsentActions.acceptNecessaryCookies()),
  acceptOptionalCookies: (options) => dispatch(ConsentActions.acceptOptionalCookies(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsent);
