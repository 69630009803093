import { preventUnauthorizedRequest } from "../../utils/interceptors";

export const Types = {
  GET_COUNTIES: "GET_COUNTIES",
  GET_COUNTIES_COMPLETE: "GET_COUNTIES_COMPLETE",
  GET_COUNTIES_FAILURE: "GET_COUNTIES_FAILURE",
  GET_CITIES: "GET_CITIES",
  GET_CITIES_COMPLETE: "GET_CITIES_COMPLETE",
  GET_CITIES_FAILURE: "GET_CITIES_FAILURE",
  GET_SETTLEMENTS: "GET_SETTLEMENTS",
  GET_SETTLEMENTS_COMPLETE: "GET_SETTLEMENTS_COMPLETE",
  GET_SETTLEMENTS_FAILURE: "GET_SETTLEMENTS_FAILURE",
};

//get counties
export const setGetCountiesPending = dispatch => {
  return { type: Types.GET_COUNTIES };
}

export const getCountiesFailure = (data) => ({
  type: Types.GET_COUNTIES_FAILURE,
  data
});

export const getCountiesComplete = (data) => ({ type: Types.GET_COUNTIES_COMPLETE, data });

export const getCounties = () => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetCountiesPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/administrativeunits/counties`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getCountiesComplete(responseJson));
    }
    else {
      dispatch(getCountiesFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get cities
export const setGetCitiesPending = dispatch => {
  return { type: Types.GET_CITIES };
}

export const getCitiesFailure = (data) => ({
  type: Types.GET_CITIES_FAILURE,
  data
});

export const getCitiesComplete = (data) => ({ type: Types.GET_CITIES_COMPLETE, data });

export const getCities = (countyRefId) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetCitiesPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/administrativeunits/cities?countyRefId=${countyRefId}`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getCitiesComplete(responseJson));
    }
    else {
      dispatch(getCitiesFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get settlements
export const setGetSettlementsPending = dispatch => {
  return { type: Types.GET_SETTLEMENTS };
}

export const getSettlementsFailure = (data) => ({
  type: Types.GET_SETTLEMENTS_FAILURE,
  data
});

export const getSettlementsComplete = (data) => ({ type: Types.GET_SETTLEMENTS_COMPLETE, data });

export const getSettlements = (cityRefId) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetSettlementsPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/administrativeunits/settlements?cityRefId=${cityRefId}`,
      {
        method: "GET",
        headers
      });

    response = preventUnauthorizedRequest(response);
    if (!response) {
      return;
    }

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getSettlementsComplete(responseJson));
    }
    else {
      dispatch(getSettlementsFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
