export const Types = {
  GET_PESTS: "GET_PESTS",
  GET_PESTS_COMPLETE: "GET_PESTS_COMPLETE",
  GET_PESTS_FAILURE: "GET_PESTS_FAILURE",
  GET_SELECTED_PEST:"GET_SELECTED_PEST",
  GET_SELECTED_PEST_COMPLETE:"GET_SELECTED_PEST_COMPLETE",
  GET_SELECTED_PEST_FAILURE:"GET_SELECTED_PEST_FAILURE",
};

//get pests list
export const setGetPestsPending = dispatch => {
  return { type: Types.GET_PESTS };
}

export const getPestsComplete = (data) => ({ type: Types.GET_PESTS_COMPLETE, data });


export const getPestsFailure = (data) => ({
  type: Types.GET_PESTS_FAILURE,
  data
});

export const getPests = () => async (dispatch, getState) => {
  const { user } = getState();
  dispatch(setGetPestsPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/pests`,
      {
        method: "GET",
        headers
      });

    //response = preventUnauthorizedRequest(response);
    //if (!response) {
    //  return;
    //}

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getPestsComplete(responseJson));
    }
    else {
      dispatch(getPestsFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};

//get single pest
export const setGetSelectedPestPending = dispatch => {
  return { type: Types.GET_SELECTED_PEST };
};

export const getSelectedPestComplete = (data) => ({
  type: Types.GET_SELECTED_PEST_COMPLETE,
  data
});

export const getSelectedPestFailure = data => ({
  type: Types.GET_SELECTED_PEST_FAILURE,
  data
});

export const getSelectedPest = (guid) => async (dispatch, getState) => {
  const { user } = getState();

  dispatch(setGetSelectedPestPending());

  const headers = new Headers();
  if (user && user.token) {
    headers.append("Authorization", `Bearer ${user.token}`);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v.1/pests/${guid}`,
      {
        method: "GET",
        headers
      });

    const responseJson = await response.json();

    if (response.ok) {
      dispatch(getSelectedPestComplete(responseJson));
    }
    else {
      dispatch(getSelectedPestFailure(responseJson));
    }
  }
  catch (e) {
    console.log("err", e);
  }
};
